import { Users } from '@or-sdk/users';
import _ from 'lodash';
import config from '@/config';
import { useAuth } from '@/stores';

const instance = new Users({
  sdkUrl: `${config.SDK_API_URL}`,
  token: () => {
    return `USER ${_.get(useAuth(), 'token')}`;
  },
});

export default instance;


import { Tag } from '@or-sdk/tags';
import { useVModel } from '@vueuse/core';
import { defineComponent, PropType, ref } from 'vue';
import {
  OrButtonV3 as OrButton,
  OrCheckboxV3 as OrCheckbox,
  OrIconV3 as OrIcon,
  OrModalV3 as OrModal,
} from '@onereach/ui-components';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'OrTagSelector',
  components: {
    OrButton,
    OrCheckbox,
    OrIcon,
    OrModal,
  },
  props: {
    /**
     * List of Tag objects to select from.
     */
    items: {
      type: Array as PropType<Tag[]>,
      default: () => [] as Tag[],
    },
    /**
     * List of selected tag ids, used as a component's model.
     */
    selected: {
      type: Array as PropType<string[]>,
      default: () => [] as string[],
    },
  },
  emits: ['createTag', 'deleteTag', 'update:selected'],
  setup(props, { emit }) {
    const { t } = useI18n();

    // MODELS
    const selectedTagsModel = useVModel(props, 'selected', emit);

    // TEMPLATE REFS
    const tagDialogRef = ref<InstanceType<typeof OrModal>>();

    // REFS
    const dirtyModel = ref<string[]>([]);

    // COMPUTED

    // METHODS
    function onTrigger() {
      dirtyModel.value = selectedTagsModel.value;
      tagDialogRef.value?.open();
    }

    function onCancel() {
      tagDialogRef.value?.close();
    }
    function onApply() {
      selectedTagsModel.value = dirtyModel.value;
      tagDialogRef.value?.close();
    }

    return {
      t,
      // models
      selectedTagsModel,
      // refs
      tagDialogRef,
      dirtyModel,
      // computed
      // methods
      onTrigger,
      onCancel,
      onApply,
    };
  },
});


import FlowGraphD3 from '@/components/flowGraph/FlowGraphD3';
import { useFlowGraphStore } from '@/stores';
import { OrIconButton as OrIconButton } from '@onereach/ui-components';
import { Flow } from '@or-sdk/deployer';
import { Deployment } from '@or-sdk/deployments';
import _ from 'lodash';
import { mapActions, mapState } from 'pinia';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FlowGraph',
  components: {
    OrIconButton,
  },
  props: {
    flowsWithTags: {
      type: Array as PropType<Flow[]>,
      default: () => [] as Flow[],
    },
    deployments: {
      type: Array as PropType<Deployment[]>,
      default: () => [] as Deployment[],
    },
  },

  data() {
    return {
      graph: null as FlowGraphD3 | null,
    };
  },

  computed: {
    ...mapState(useFlowGraphStore, ['nodes', 'links', 'deployedFlows'/*, 'selectedNodeId'*/]),
    // hiddenFlowsVisible: ({ pageConfigs }) => pageConfigs.hiddenFlowsVisible,
  },

  watch: {
    nodes(newNodes) {
      this.graph?.update(newNodes, this.links);
    },
    links(newLinks) {
      this.graph?.update(this.nodes, newLinks);
    },
 /*
    hiddenFlowsVisible() {
      this.getGraphData();

      this.renderGraph('#graph', {
        nodes: this.nodes,
        relationships: this.relationships,
      });
    },
*/
  },

  mounted() {
    this.graph = new FlowGraphD3('#graph', [], [], {});
    this.prepareGraphData();
    // this.renderGraph('#graph', this.nodes, this.relationships);
  },

  methods: {
    ...mapActions(useFlowGraphStore, [
      'prepareGraphData',
    //   'setNodes',
    //   'setRelationships',
    //   'setSelectedNodeId',
    ]),


/*
    renderGraph(selector: string, nodes: FlowGraphNode[] = [], links: FlowGraphLink[] = []): void {
      this.clearGraphContainer(selector);
      this.graph = useGraphContainer().initGraph(selector, {
        minCollision: 150,
        nodes,
        links,
      });
      /!*
      this.graph = new Graph(selector, {
        zoomToFit: false,

        selectedNodeId: this.selectedNodeId,
        onNodeClick: this.onNodeClick,
      }) as unknown | null;

       *!/
    },
*/

    clearGraphContainer(selector: string) {
      const normalizedSelector = _.replace(selector, '#', '');
      const containerEl = document.getElementById(normalizedSelector);
      if (containerEl) containerEl.innerHTML = '';
    },
/*
      onNodeClick(node?: unknown) {
        if (!this.graph) {
          return;
        }

        // this.setSelectedNodeId(node.id);
        //
        // this.graph.selectNode(node.id);
        //
        // this.$emit('node-click', {
        //   selectedNode: node,
        //   nodes: this.nodes,
        //   relationships: this.relationships,
        // });
      },

      hightlightNodes(nodeIds) {
        if (!this.graph) {
          return;
        }

        this.graph.highlightNodes(nodeIds);
      },

      hightlightRelationships(relationshipsIds) {
        if (!this.graph) {
          return;
        }

        this.graph.highlightRelationships(relationshipsIds);
      },

      unhightlightNodes(nodeIds) {
        if (!this.graph) {
          return;
        }

        this.graph.unhighlightNodes(nodeIds);
      },

      unhightlightRelationships(relationshipsIds) {
        if (!this.graph) {
          return;
        }
        this.graph.unhighlightRelationships(relationshipsIds);
      },
  */

    zoomToFit() {
      this.graph?.zoomToFit();
    },

    zoomIn() {
      this.graph?.zoomIn();
    },

    zoomOut() {
      this.graph?.zoomOut();
    },
  },

});

// import { mapActions, mapState } from 'vuex';
// import { messageBus } from '@onereach/ui';

// eslint-disable-next-line sort-imports


/* export default {
  //
  // created() {
  //   messageBus.$on('flow-graph::go-to-selected-flow', this.zoomToSelectedFlow);
  // },
  //
  // beforeDestroy() {
  //   messageBus.$off('flow-graph::go-to-selected-flow', this.zoomToSelectedFlow);
  // },
  // };
 */

import { TagsApi } from '@/api';
import { ListTagsParams, Tag } from '@or-sdk/tags';
import { defineStore } from 'pinia';

export const tagStore = defineStore('tag', {
  state: () => {
    return {
      /**
       * All tags for the account.
       * Note. Amount expected not to be huge, so it could cause a performance issue.
       */
      allTags: [] as Tag[],
    };
  },
  actions: {
    async fetchAll() {
      try {
        const tagList = await TagsApi.listTags({
          query: {
            isDeleted: false,
          },
          // todo: add `order` to SDK and remove casting
          order: `"data"->'label'`,
        } as ListTagsParams);
        this.allTags = tagList.items;
      } catch (e) {
        console.error('Error fetching tags:', e);
        // todo: implement error handling, notify user
        this.allTags = [];
      }
    },
  },
});

export default tagStore;

import { BotsApi } from '@/api';
import { useFlowStore, useSortingStore } from '@/stores/index';
import { BotId } from '@/types';
import { BotsByIdMap, BotWithFlows, ExistingBot } from '@/types/catalog';
import { Sorting } from '@/types/OrSortingSelector';
import { Bot, ListBotsParams } from '@or-sdk/bots';
import _ from 'lodash';
import { defineStore } from 'pinia';

/**
 * Fetches all bots whose name contains given search phrase. Or all bots if empty or not specified.
 * @param searchPhrase String to search in the bot's name.
 * todo: move to helper or other appropriate place
 */
async function _fetchBots(searchPhrase?: string): Promise<ExistingBot[]> {
  let query;
  if (searchPhrase) {
    query = {
      // todo: escape special characters and SQL injections
      'data.label': { iLike: `%${searchPhrase}%` },
    };
  }
  const botsList = await BotsApi.listBots({
    query,
    projection: [
      'id',
      'data.label',
      'data.color',
      'data.config',
      'data.password',
      'dateModified',
      'tags',
    ],
    order: `lower("data"->>'label')`,
  } as ListBotsParams);
  return botsList.items as ExistingBot[];
}

function sortBots(bots: BotWithFlows[], sorting: Sorting) {
  switch (sorting.property) {
    case 'dateModified':
      return _.orderBy(bots, ['dateModified'], [sorting.order]);
    case 'dateActivated':
      // As only flows have activation date the bots are being sorted A-Z
      return _.orderBy(bots, [(bot) => bot.data.label.toLowerCase()], ['asc']);
    case 'name':
    default:
      return _.orderBy(bots, [(bot) => bot.data.label.toLowerCase()], [sorting.order]);
  }
}

/**
 * Finds by id the current bot in the given bots array and replaces it with new version.
 * @param bots Array to replace a bot within.
 * @param updatedBot Updated bot info assuming the id remains the same.
 */
function _replaceCurrent(bots: ExistingBot[], updatedBot: Bot): ExistingBot[] {
  if (updatedBot.id) {
    return bots.map(bot => bot.id === updatedBot.id ? updatedBot as ExistingBot : bot);
  } else {
    return bots;
  }
}

export const botStore = defineStore('bot', {
  state: () => {
    return {
      /**
       * Bot selected for displaying details
       */
      currentBot: null as Bot | null,
      /**
       * All bots available for the user
       * todo: the approach may be revised in case of performance issues or paging support implementation
       */
      allBots: [] as ExistingBot[],
      /**
       * List of bots that are expanded on UI and need flows to be loaded
       */
      expandedBots: new Set<BotId>(),
      /**
       * List of bots that are being expanded and need to show loading indicator
       */
      botIsLoadingItsFlows: new Set<BotId>(),
      /**
       * Search term to filter bots.
       */
      searchTerm: '',
      /**
       * Flag to define initial application loading; not to blink application internals before the loading page.
       */
      appInited: false,
    };
  },

  getters: {
    currentBotId(state) {
      return state.currentBot?.id;
    },
    getById() {
      return (botId: BotId) => {
        return this.allBotsMap[botId];
      };
    },

    /**
     * Bot ID - Bot pairs map for the faster bot access,
     */
    allBotsMap(state): BotsByIdMap {
      return _.keyBy(state.allBots, 'id');
    },

    /**
     * Subset of bots that correspond certain filters and search criteria,
     * Enriched with flows and view metadata.
     * Sorted by the order specified in the sorting store.
     */
    filteredBots(): BotWithFlows[] {
      const sortingStore = useSortingStore();
      return sortBots(this._unsortedBots, sortingStore.catalogSorting);
    },

    /**
     * Subset of bots that correspond certain filters and search criteria.
     * Enriched with flows and view metadata.
     * @private Use filteredBots as a public getter.
     */
    _unsortedBots(state): BotWithFlows[] {
      const flowStore = useFlowStore();
      const matchingBots: BotWithFlows[] = [];

      state.allBots.forEach((bot: ExistingBot) => {
        const botId = bot.id;
        const expanded = state.expandedBots.has(botId);
        const botIsLoadingItsFlows = state.botIsLoadingItsFlows.has(botId);
        const botMatches = bot.data.label.toLowerCase().includes(state.searchTerm);
        const flows = flowStore.getFilteredFlowsByBotId(botId, botMatches);
        const botWithFlows: BotWithFlows = {
          ...bot,
          flows,
          view: {
            isExpanded: expanded,
            botIsLoadingItsFlows,
          },
          vforkey: botId,
        };

        if (!state.searchTerm || botMatches || !!botWithFlows.flows?.length) matchingBots.push(botWithFlows);
      });

      return matchingBots;
    },
  },

  actions: {
    /**
     * Fetches all bots and select one with the given id (if passed).
     * @param selectedBotId Identifier of the bot to select after fetching.
     */
    async fetchAllBots(selectedBotId?: BotId) {
      try {
        this.allBots = await _fetchBots();
        if (selectedBotId) {
          await this.fetchBot(selectedBotId);
        } else {
          this.currentBot = null;
        }
      } finally {
        this.appInited = true;
      }
    },

    /**
     * Finds bots and flows that contain specified substring in their names. Case-insensitive.
     * @param searchTerm String to search in the name.
     */
    async search(searchTerm: string) {
      const flowStore = useFlowStore();
      flowStore.searchTerm = searchTerm;
      if (searchTerm) {
        // search bots
        // -- no need for backend search for bots as we already have all of them; may change when paging come
        // search flows
        await flowStore.searchFlows(searchTerm);
      }

      // fetch flows for expanded bots; it is needed 1) on search reset 2) if bot name matches with the search term
      this.expandedBots.forEach(botId => {
        flowStore.fetchAllBriefFlowsForBot(botId);
      });

      // call it AFTER the flows have been read to narrow the bot list
      this.searchTerm = searchTerm.toLowerCase();
    },

    /**
     * Finds and selects bot with the given id. Resets `currentBot` to `null` if not found.
     * @param botId Bot identifier
     */
    selectCurrentBot(botId: string | undefined) {
      let bot;
      if (botId) bot = this.getById(botId);
      this.currentBot = bot || null;
    },

    /**
     * Toggles `expanded` state of the given bot
     */
    toggleExpanded(botId: BotId) {
      if (this.expandedBots.has(botId)) {
        this.expandedBots.delete(botId);
      } else {
        this.expandedBots.add(botId);
      }
    },

    /**
     * Defines if bot's flows are loading
     */
    setFlowsAreLoading(botId: BotId, isLoading: boolean) {
      if (isLoading) {
        this.botIsLoadingItsFlows.add(botId);
      } else {
        this.botIsLoadingItsFlows.delete(botId);
      }
    },

    /**
     * Fetches detailed info of the bot with given id.
     * @param botId Bot identifier
     */
    async fetchBot(botId: BotId) {
      try {
        // todo: check if params needed
        this.currentBot = await BotsApi.getBot(botId);
      } catch (e) {
        console.error('Error fetching bot:', e);
        // todo: implement error handling, notify user
        this.currentBot = null;
      }
    },

    async saveBot(bot: Bot) {
      try {
        this.currentBot = await BotsApi.saveBot(bot);
        this.allBots = _replaceCurrent(this.allBots, this.currentBot);
      } catch (e) {
        console.error('Error saving bot:', e);
        // todo: implement error handling, notify user
        this.currentBot = null;
      }
    },
  },
});

export default botStore;

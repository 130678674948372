
import OrMessagePage from '@/components/library/OrMessagePage.vue';
import { useBotStore } from '@/stores';
import { AuthPopover } from '@onereach/auth-ui-module';
import { LocalizedServiceTranslations, ServiceNavigationHeader } from '@onereach/service-navigation';
import {
  EmptyStateSize,
  OrSidebarV3 as OrSidebar,
} from '@onereach/ui-components';
import { StoreGeneric, storeToRefs } from 'pinia';
import { computed, defineComponent, PropType, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AppPage',
  components: {
    AuthPopover,
    OrMessagePage,
    OrSidebar,
    ServiceNavigationHeader,
  },
  props: {
    token: {
      type: String,
      required: false,
      default: null,
    },
    config: {
      type: Object,
      required: true,
    },
    serviceTranslations: {
      type: Object as PropType<LocalizedServiceTranslations>,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const botStore = useBotStore();
    const { appInited, activeLoaders } = storeToRefs(botStore as unknown as StoreGeneric);
    const isInitialLoading = computed(() => {
      return !appInited.value || (activeLoaders as Ref<string[]>).value.includes('fetchAllBots');
    });
    return {
      t,
      isInitialLoading,
      EmptyStateSize: computed(() => EmptyStateSize),
    };
  },
  computed: {
    breadcrumbs(): string[] {
      return this.$route.matched
        .map(({ meta }) => meta.breadcrumb)
        .filter(Boolean)
        .map((breadcrumb) => {
          return typeof breadcrumb === 'function' ? breadcrumb.call(this) : breadcrumb;
        });
    },
  },
});

import _ from 'lodash';
import * as d3 from 'd3';

export default class Graph {
  node;
  nodes;
  zoom = null;
  currentTransform = { k: 1, x: 0, y: 0 };
  relationship;
  relationships;
  options = {
    arrowSize: 4,
    minCollision: undefined,
    nodeRadius: 50,
    zoomFit: false,

    selectedNodeId: null,
  };

  constructor(selector, options) {
    this.options = options;

    this.init(selector, options);
  }

  init(selector, options) {
    _.merge(this.options, options);

    if (!this.options.minCollision) {
      this.options.minCollision = this.options.nodeRadius * 2;
    }

    // MOVED this.zoom = d3.zoom();
    // MOVED this.zoom.scaleExtent([0.1, 3]).on('zoom', this.zoomer.bind(this));
    // MOVED this.appendGraph(selector);
    // MOVED this.simulation = this.initSimulation();
    // MOVED this.updateGraphData();

    // if (this.options.selectedNodeId) {
    //   this.selectNode(this.options.selectedNodeId, false);
    // }
    //
    // if (!_.size(this.nodePairs)) {
    //   this.setNodePairs();
    //   this.positionNodePairsArrows();
    // }
  }

  // appendNode(nodeSelection) {
    // const resultNode = nodeSelection.enter()
    //   .append('g');
      // .attr('class', d => {
      //   let classes = 'node';
      //
      //   if (_.get(d, 'deactivated')) {
      //     classes += ' node-deactivated';
      //   }
      //
      //   if (_.get(d, 'entryPoint')) {
      //     classes += ' node-highlighted';       WHY??
      //   }
      //
      //   return classes;
      // })
      // .attr('id', d => `node_${d.id}`);          WHY??
    /*      .on('click', (d) => {
            // d.fx = d3.event.x;
            // d.fy = d3.event.y;

            if (typeof this.options.onNodeClick === 'function') {
              this.options.onNodeClick(d);
            }
          })
          .on('dblclick', d => {
            this.stickNode(d);

            if (typeof this.options.onNodeDoubleClick === 'function') {
              this.options.onNodeDoubleClick(d);
            }
          });*/
    //    .on('mouseenter', function(d) {
    //        if (info) {
    //            updateInfo(d);
    //        }

    //        if (typeof options.onNodeMouseEnter === 'function') {
    //            options.onNodeMouseEnter(d);
    //        }
    //    })
    //    .on('mouseleave', function(d) {
    //        if (info) {
    //            clearInfo(d);
    //        }

    //        if (typeof options.onNodeMouseLeave === 'function') {
    //            options.onNodeMouseLeave(d);
    //        }
    //    })
    //    .call(d3.drag()
    //            .on('start', dragStarted)
    //            .on('drag', dragged)
    //            .on('end', dragEnded));

  //   return resultNode;
  // }

  wrapText(node) {
    const self = d3.select(this);
    Graph.prototype.chunkifyLabelText(self, node);
  }

  chunkifyLabelText(textNode, node) {
    const label = _.get(node, 'label', '');
    const maxLineLength = node.nodeRadius / 5;

    const arr = this.chunkString(label, maxLineLength, 2);

    _.forEach(arr, (line, index) => {
      textNode.append('tspan')
        .text(line)
        .attr('text-anchor', 'middle')
        .attr('x', 0)
        .attr('dy', () => `${index}em`);
    });

    return textNode;
  }

  chunkString(str, maxLineLength, maxLines) {
    const arrayWords = _.split(str, ' ');
    let done = false;
    let i = 0;
    const arrayOutput = [''];

    while (!done && i < arrayWords.length) {
      const word = arrayWords[i];
      const wordLength = word.length;
      const last = arrayOutput.length - 1;
      const currentLength = arrayOutput[last].length;
      // add new word to the line
      if (currentLength + wordLength < maxLineLength) {
        arrayOutput[last] += `${word} `;
        i += 1;
        // skip space at the end of line
      } else if (currentLength + wordLength === maxLineLength) {
        arrayOutput[last] += word;
        i += 1;
        // looks like it's a last word in the last line
      } else if (arrayOutput.length === maxLines) {
        arrayOutput[last] += word;
        arrayOutput[last] = _.truncate(arrayOutput[last], {
          length: maxLineLength,
          separator: '',
        });
        done = true;
        // put whole word on the next line if it fits
      } else if (wordLength < maxLineLength && arrayOutput.length < maxLines) {
        arrayOutput.push(`${word} `);
        i += 1;
        // skip space at the end of line
      } else if (wordLength === maxLineLength && arrayOutput.length < maxLines) {
        arrayOutput.push(`${word}`);
        i += 1;
        // put what fits of the word on the next line and carry over the remainder
      } else if (wordLength > maxLineLength && arrayOutput.length < maxLines) {
        // in case the first word happens to be very long (doesn't fit line)
        if (_.isEmpty(arrayOutput[last])) {
          arrayOutput[last] = word.substr(0, maxLineLength);
        } else {
          arrayOutput.push(word.substr(0, maxLineLength));
        }
        arrayWords[i] = word.substring(maxLineLength, word.length);
      }
    }
    return arrayOutput;
  }


  render() {
    // let node;
    // let nodes;
    // let nodePairs = [];
    // let relationship;
    // let relationshipText;
    // let relationships;
    // let simulation;
    // let svg;
    // let zoom = null;
    // let currentTransform = { k: 1, x: 0, y: 0 };
    // let options;

    /*
    //SELECTION

    function selectNode(nodeId, unselectPrevNode = true) {
      const node = getNodeById(nodeId);

      if (unselectPrevNode) {

        unselectNode(options.selectedNodeId);
      }

      if (!node) return;

      node.classList.add('node-selected');
      options.selectedNodeId = nodeId;
    }

    function unselectNode(nodeId) {
      const node = getNodeById(nodeId);
      if (!node) return;

      node.classList.remove('node-selected');

      options.selectedNodeId = null;
    }

    function highlightNodes(nodeIds = []) {
      const nodes = getNodesByIds(nodeIds);

      _.forEach(nodes, node => {
        node.classList.add('node-highlighted');
      });
    }

    function unhighlightNodes(nodeIds = []) {
      const domNodes = getNodesByIds(nodeIds);

      _.forEach(domNodes, node => {
        const dataNode = _.find(nodes, { id: node.id.split('_')[1] });
        if (dataNode.entryPoint) return;

        node.classList.remove('node-highlighted');
      });
    }

    function highlightRelationships(relationshipsIds = []) {
      const relationships = getRelationshipsByIds(relationshipsIds);

      _.forEach(relationships, relationship => {
        relationship.classList.add('selected');
      });
    }

    function unhighlightRelationships(relationshipsIds = []) {
      const relationships = getRelationshipsByIds(relationshipsIds);

      _.forEach(relationships, relationship => {
        relationship.classList.remove('selected');
      });
    }

    function getNodesByIds(nodeIds) {
      return _.map(nodeIds, nodeId => getNodeById(nodeId));
    }

    function getNodeById(nodeId) {
      return document.getElementById(`node_${nodeId}`);
    }

    function getRelationshipsByIds(relationshipIds = []) {
      return _.map(relationshipIds, relationshipId => getRelationshipById(relationshipId));
    }

    function getRelationshipById(relationshipId) {
      return document.getElementById(`relation_${relationshipId}`);
    }

*/

    return {
      // selectNode,
      // unselectNode,
      // highlightNodes,
      // unhighlightNodes,
      // highlightRelationships,
      // unhighlightRelationships,
    };
  }
}


import { SortingOption } from '@/types/OrSortingSelector';
import _ from 'lodash';
import { ref, computed, defineComponent, PropType, onMounted, Ref } from 'vue';
import {
  OrButtonV3 as OrButton,
  OrIconV3 as OrIcon,
  OrMenuV3 as OrMenu,
  OrMenuItemV3 as OrMenuItem,
} from '@onereach/ui-components';

export default defineComponent({
  name: 'OrSortingSelector',
  components: {
    OrButton,
    OrIcon,
    OrMenu,
    OrMenuItem,
  },

  props: {
    options: {
      type: Array as PropType<SortingOption[]>,
      required: true,
      default: () => [],
    },
    selected: {
      type: [String, Object] as PropType<SortingOption | undefined>,
      default: undefined,
    },
  },

  emits: ['update:selected'],

  setup: function (props, { emit }) {
    const sortButtonRef = ref(null);
    const sortMenuRef = ref(null);

    function getLabel(option: SortingOption) {
      return typeof option === 'string' ? option : option?.label;
    }

    const sortLabels = computed(() => {
      return props.options.map(item => getLabel(item));
    });

    const optionMap = computed(() => {
      return _.keyBy(props.options, item => getLabel(item));
    });

    const selectedOption: Ref<SortingOption | undefined> = ref();

    onMounted(() => {
      // match selected from props or select first option in the list
      const selectedLabel = props.selected ? getLabel(props.selected) : undefined;
      const matchedValue = selectedLabel && props.options.find(option => getLabel(option) === selectedLabel);
      selectedOption.value = matchedValue || props.options[0];
    });

    function isSelected(optionLabel: string) {
      return selectedOption.value ? doesMatch(selectedOption.value, optionLabel) : false;
    }

    function onOptionSelected(optionLabel: string) {
      const newValue = optionMap.value[optionLabel];
      selectedOption.value = newValue;
      emit('update:selected', newValue);
    }

    function doesMatch(item: SortingOption, optionLabel: string) {
      if (typeof item === 'string') {
        return item === optionLabel;
      } else {
        return item?.label === optionLabel;
      }
    }

    return {
      sortButtonRef,
      sortMenuRef,
      sortLabels,
      selectedOption,
      getLabel,
      isSelected,
      onOptionSelected,
    };
  },
});

import { RouteLocationNormalized } from 'vue-router';

import type { useAuth } from '@/stores';

type AuthStore = ReturnType<typeof useAuth>;

export default async (to: RouteLocationNormalized, store: AuthStore, authUrl: string): Promise<void> => {
  const initialPath = to.path;
  if (to.meta.requiresAuth) {
    if (store.authorized == null) {
      try {
        await store.init({ initialPath });
      } catch (e) {
        console.error(e);
      }
    }
    if (!store.authorized) {
      const encodedRedirectPath = encodeURIComponent(window.location.href);
      const authPath = `${authUrl}/login?redirectPath=${encodedRedirectPath}`;
      window.location.replace(authPath);
    }
  }
};

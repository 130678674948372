import _ from 'lodash';
import config from '../config';
import { default as useAuth } from '@/stores/auth';

//todo: API config could have common interface on the backend to be typed not as any
export default function getDataHubApi<T>(apiClass: new(apiConfig: any) => T): T {
  return new apiClass({
    dataHubSvcUrl: `${config.DATA_HUB_URL}`,
    token: () => {
      return `USER ${_.get(useAuth(), 'token')}`;
    },
  });
}

import { useGraphLinks } from '@/components/flowGraph/links';
import { useGraphNodes } from '@/components/flowGraph/nodes';
import {
  D3SvgGroupSelection,
  D3SvgLinkGroupSelection,
  D3SvgNodeGroupSelection,
  D3SvgSelection,
  FlowGraphForcedLinkDatum,
  FlowGraphForcedNodeDatum,
  FlowGraphOptions,
} from '@/types';
import * as d3 from 'd3';

const nodeHelper = useGraphNodes();
const linkHelper = useGraphLinks();

export function useGraphContainer() {
  /**
   * Appends SVG element to the container with given selector.
   * @param selector DOM selector of HTML container.
   * @returns D3 selection of appended SVG
   */
  function appendSvg(selector: string): D3SvgSelection {
    const container = d3.select(selector);

    return container.append('svg')
      .attr('class', 'svg-wrapper')
      .attr('width', '100%')
      .attr('height', '100%');
  }

  /**
   * Appends SVG Group element to the given SVG.
   * @param svg D3 selection of the SVG element, no Datum specified.
   * @returns D3 selection of the appended SVG Group
   */
  function appendMainGroup(svg: D3SvgSelection): D3SvgGroupSelection {
    return (svg.append('g') as unknown as D3SvgGroupSelection)
      .attr('class', 'graph-wrapper')
      .attr('width', '100%')
      .attr('height', '100%');
  }

  /**
   * Appends SVG structure to the container with given selector.
   * @param selector
   */
  function appendGraph(selector: string) {
    const svg = appendSvg(selector);
    const svgGroup = appendMainGroup(svg);

    // todo: 'relationships' can be renamed; kept now till CSS refactoring
    const svgLinks = (svgGroup.append('g') as D3SvgLinkGroupSelection)
      .attr('class', 'relationships');

    const svgLink = svgLinks.selectAll<SVGGElement, FlowGraphForcedLinkDatum>('.relationship');

    // put nodes after links so they overlap them
    const svgNodes = (svgGroup.append('g') as D3SvgNodeGroupSelection)
      .attr('class', 'nodes');

    const svgNode = svgNodes.selectAll<SVGGElement, FlowGraphForcedNodeDatum>('.node');

    const svgDefs = appendDefs(svgGroup);

    return {
      svg,
      svgGroup,
      svgNodes,
      svgNode,
      svgLinks,
      svgLink,
      svgDefs,
    };
  }

  // todo: try to make all static
  let options: FlowGraphOptions;

  /**
   * Initializes and renders flow graph in the HTML element with given selector.
   * @param selector DOM selector of the element to insert the graph into.
   * @param _options Graph options
   */
  function initGraph(selector: string, _options?: FlowGraphOptions) {
    if (_options) options = _options;
    const graph = appendGraph(selector);
    updateGraphData();
    return graph;
  }

  /**
   * Adds physics to the graph.
   */
  function useForce(svg: D3SvgSelection,
                    svgNodes: D3SvgNodeGroupSelection,
                    svgLinks: D3SvgLinkGroupSelection
  ) {
    const containerEl = svg.node()?.parentElement;
    if (!containerEl) return;

    const centerX = containerEl.clientWidth / 2;
    const centerY = containerEl.clientHeight / 2;

    return d3.forceSimulation<FlowGraphForcedNodeDatum, FlowGraphForcedLinkDatum>()
      .velocityDecay(0.8)
      .alphaMin(.2)
      .force('collide',
        d3.forceCollide<FlowGraphForcedNodeDatum>().radius((d) =>
          d.nodeRadius + 120/*options.minCollision ||*/
        ).iterations(2)
      )
      .force('charge', d3.forceManyBody())
      // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .force('link', d3.forceLink<FlowGraphForcedNodeDatum, FlowGraphForcedLinkDatum>()
        .id(d => d.id).distance(300).strength(1)
      )
      // .force('center', d3.forceCenter(centerX, centerY).strength(.7))
      .force('x', d3.forceX(centerX))
      .force('y', d3.forceY(centerY))
      .on('tick', () => tick(svgNodes, svgLinks))
    ;
  }

  /**
   * Converts flow data model to D3 data model.
   */
  function updateGraphData() {
    if (options) {
      // const d3Data = convertToD3Data(options.nodes, options.links);
      // updateWithD3Data(d3Data);
    }
  }

  /**
   * Appends reusable arrow ending markers
   * @param svgGroup D3 Selection of main SVG group for adding template elements (defs)
   */
  function appendDefs(svgGroup: D3SvgGroupSelection) {
    const defs = svgGroup.append('defs');

    const arrowMarker = defs.append('marker')
      .attr('id', 'arrowhead')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '3')
      .attr('refY', '5')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', 'auto')
      .attr('fill', '#1e232b');

    const arrowMarkerInactive = defs.append('marker')
      .attr('id', 'arrowhead-inactive')
      .attr('viewBox', '0 0 10 10')
      .attr('refX', '3')
      .attr('refY', '5')
      .attr('markerWidth', '6')
      .attr('markerHeight', '6')
      .attr('orient', 'auto')
      .attr('fill', '#c6c6c6');

    arrowMarker.append('path').attr('d', 'M 0 0 L 10 5 L 0 10 z');
    arrowMarkerInactive.append('path').attr('d', 'M 0 0 L 10 5 L 0 10 z');

    return defs;
  }

  /**
   * Single graph change called by force simulation.
   * @param svgNodes Nodes SVG group selection
   * @param svgLinks Links SVG group selection
   */
  function tick(svgNodes: D3SvgNodeGroupSelection, svgLinks: D3SvgLinkGroupSelection) {
    nodeHelper.tickNodes(svgNodes);
    linkHelper.tickLinks(svgLinks);
  }

  return {
    appendGraph,
    useForce,
    initGraph,
  };
}

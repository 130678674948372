import config from '@/config';
import { useAuth } from '@/stores';
import { createRouter, createWebHistory } from 'vue-router';

import authRouterMiddleware from './authRouterMiddleware';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  await authRouterMiddleware(to, useAuth(), config.AUTH_UI_URL);
});
export default router;

import {
  CoordinatedNodeDatum, Coordinates,
  D3SvgLinkEnterSelection,
  D3SvgLinkGroupSelection,
  D3SvgLinkSelection,
  FlowGraphForcedLinkDatum,
  FlowGraphForcedNodeDatum,
  IFlowGraphLinkDatum,
  IFlowGraphNodeDatum,
  PositionedLinkDatum,
} from '@/types';
import { FlowGraphLinkDatum } from '@/types/FlowGraphLinkDatum';
import * as d3 from 'd3';
import { SimulationLinkDatum } from 'd3';
import _ from 'lodash';

type NodePair = {
  firstNodeId: string;
  secondNodeId: string;
};

/**
 * Helper methods for manipulating flow graph links
 */
export function useGraphLinks() {
  /**
   * Upgrades links with properties required by d3. Sorts and counts them to properly position parallel ones.
   * @param newLinks New link data
   * @param oldLinks Existing, D3 enriched link data
   * @param nodes Nodes list to populate source and target
   */
  function convertToD3(newLinks: IFlowGraphLinkDatum[],
                       oldLinks: IFlowGraphLinkDatum[],
                       nodes: IFlowGraphNodeDatum[]): FlowGraphForcedLinkDatum[] {
    let d3Links: IFlowGraphLinkDatum[] = [];

    newLinks.forEach(link => {
      const oldLink = _.find(oldLinks, { id: link.id });
      if (oldLink) {
        Object.assign(oldLink, link);
        d3Links.push(oldLink);
      } else {
        const source = _.find(nodes, { id: link.startNode });
        const target = _.find(nodes, { id: link.endNode });

        if (source && target) {
          // todo: create class
          d3Links.push(new FlowGraphLinkDatum({
            ...link,
            id: `${link.startNode}_${link.endNode}_${link.triggerType}`,
            source,
            target,
          }));
        }
      }
    });

    d3Links = _.sortBy(d3Links, ['startNode', 'endNode']);

    for (let i = 0; i < d3Links.length; i++) {
      if (i !== 0 &&
        d3Links[i].startNode === d3Links[i - 1].startNode &&
        d3Links[i].endNode === d3Links[i - 1].endNode) {
        d3Links[i].linkCounter = d3Links[i - 1].linkCounter + 1;
      } else {
        d3Links[i].linkCounter = 1;
      }
    }

    _positionParallelLinks(d3Links, _getNodePairs(d3Links));

    return d3Links;
  }

  /**
   * @private
   * Builds array of node pair combinations connected by given list of links.
   * Both directions go to the same pair.
   * @param relationships
   */
  function _getNodePairs(relationships: IFlowGraphLinkDatum[]): NodePair[] {
    return _.reduce(relationships, (pairs, relationship) => {
      const existingPair = _.find(pairs, {
        firstNodeId: relationship.source.id,
        secondNodeId: relationship.target.id,
      });
      const existingReversePair = _.find(pairs, {
        firstNodeId: relationship.target.id,
        secondNodeId: relationship.source.id,
      });

      if (!existingPair && !existingReversePair) {
        pairs.push({
          firstNodeId: relationship.source.id,
          secondNodeId: relationship.target.id,
        });
      }

      return pairs;
    }, [] as NodePair[]);
  }

  /**
   * @private
   * Calculates vertical (as before rotation) position of the links connecting same nodes.
   * @param relationships List of links
   * @param nodePairs List of connected node pairs
   */
  function _positionParallelLinks(relationships: IFlowGraphLinkDatum[], nodePairs: NodePair[]) {
    _.forEach(nodePairs, ({ firstNodeId, secondNodeId }) => {
      const inConnections = _.filter(relationships, relationship =>
        relationship.target.id === firstNodeId && relationship.source.id === secondNodeId);
      const outConnections = _.filter(relationships, relationship =>
        relationship.source.id === firstNodeId && relationship.target.id === secondNodeId);

      const size = _.size(inConnections) + _.size(outConnections);
      const coef = ((size - 1) * 30) / 2;

      _.forEach(inConnections, (relationship, index) => {
        relationship.position = coef - 30 * index;
      });

      _.forEach(outConnections, (relationship, index) => {
        relationship.position = -(coef - 30 * (index + _.size(inConnections)));
      });
    });
  }

  /**
   * Reconstructs D3 DOM using d3.Selection.join by adding/updating/removing links.
   * Requires consistent datum id to update correctly.
   * @param svgLinks D3 selection of links group element
   * @param linkData Updated links data
   */
  function updateLinks(svgLinks: D3SvgLinkGroupSelection, linkData: IFlowGraphLinkDatum[]): D3SvgLinkSelection {
    const forcedData: FlowGraphForcedLinkDatum[] = linkData; // todo: here will come the conversion
    return (svgLinks.selectAll('.relationship') as D3SvgLinkSelection)
      .data(forcedData, d => d.id)
      .join(
        enter => appendRelationshipToGraph(enter),
        update => update,
        exit => exit.remove()
      );
  }

  /**
   * Appends new link group element and its content
   * @param enter D3 enter selection - new data records that require DOM elements creation
   */
  function appendRelationshipToGraph(enter: D3SvgLinkEnterSelection) {
    const relationship = appendRelationship(enter);
    _appendOutlineToRelationship(relationship);
    _appendTextToRelationship(relationship);

    return relationship;
  }

  /**
   * @private
   * Appends main link group
   * @param enter D3 enter selection
   */

  function appendRelationship(enter: D3SvgLinkEnterSelection) {
    return enter.append('g')
      .attr('class', 'relationship');
    // .on('dblclick', d => {
    //   if (typeof options.onRelationshipDoubleClick === 'function') {
    //     options.onRelationshipDoubleClick(d);
    //   }
    // });
    //    .on('mouseenter', function(d) {
    //        if (info) {
    //            updateInfo(d);
    //        }
    //    });
  }

  /**
   * @private
   * Appends line for the link arrow
   * @param linkGroup Current link group
   */
  function _appendOutlineToRelationship(linkGroup: D3SvgLinkSelection) {
    return linkGroup.append('path')
      .attr('class', d => `outlined ${d.customTrigger ? 'inactive' : ''}`)
      .attr('fill', 'none')
      .attr('marker-end', d => d.customTrigger ? 'url(#arrowhead-inactive)' : 'url(#arrowhead)');
  }

  /**
   * @private
   * Appends test for the link as a Foreign Object that may contain HTML tags
   * @param linkGroup Current link group
   */
  function _appendTextToRelationship(linkGroup: D3SvgLinkSelection) {
    const foreignObj = linkGroup.append('foreignObject')
      .attr('class', 'text')
      .attr('transform-origin', '0 0')
      .attr('width', 200)
      .attr('height', 20);

    foreignObj.append('xhtml:div')
      .attr('class', d => `relationship-label ${d.customTrigger ? 'inactive' : ''}`)
      .html(d => `
        <div class="text-label">${d.triggerType}</div>
        <div class="counter">X${d.triggerCounter}</div>
      `);

    return foreignObj;
  }

  /**
   * Rotates a point around the given center. // todo: define CW/CCW?
   * @param cx X-coordinate of the rotation center
   * @param cy Y-coordinate of the rotation center
   * @param x X-coordinate of the rotating point
   * @param y Y-coordinate of the rotating point
   * @param angle Rotation angle in degrees
   * @returns New coordinates of the point
   */
  function rotatePoint({ x: cx, y: cy }: Coordinates, { x, y }: Coordinates, angle: number) {
    const radians = Math.PI / 180 * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - cx) + sin * (y - cy) + cx;
    const ny = cos * (y - cy) - sin * (x - cx) + cy;

    return <Coordinates>{ x: nx, y: ny };
  }

  function unitaryVector(source: Coordinates, target: Coordinates, newLength?: number) {
    const length =
      Math.sqrt(Math.pow(target.x - source.x, 2) + Math.pow(target.y - source.y, 2)) /
      Math.sqrt(newLength || 1);

    return <Coordinates>{
      x: (target.x - source.x) / length || 0,
      y: (target.y - source.y) / length || 0,
    };
  }

  function unitaryNormalVector(source: Coordinates, target: Coordinates, newLength?: number) {
    const center = { x: 0, y: 0 };
    const vector = unitaryVector(source, target, newLength);

    return rotatePoint(center, vector, 90);
  }

  function getAngle(source: Coordinates, target: Coordinates) {
    return Math.atan2((target.y ?? 0) - (source.y ?? 0), (target.x ?? 0) - (source.x ?? 0)) * 180 / Math.PI;
  }

  /**
   * Moves a link according to force simulation coordinates.
   * Correspondingly moves and rotates arrows and texts.
   * @param svgLinks
   */
  function tickLinks(svgLinks: D3SvgLinkGroupSelection) {
    (svgLinks.selectAll('.relationship') as D3SvgLinkSelection)
      .attr('transform', d => {
        const source = d.source as CoordinatedNodeDatum;
        const target = d.target as CoordinatedNodeDatum;
        const angle = getAngle(source, target);
        return `translate(${source.x}, ${source.y}) rotate(${angle})`;
      });

    _tickRelationshipsTexts(svgLinks);
    _tickRelationshipsOutlines(svgLinks);
  }

  /**
   * @private
   * Repositions link arrow
   * @param svgLinks D3 Selection of links container
   */
  function _tickRelationshipsOutlines(svgLinks: D3SvgLinkGroupSelection) {
    svgLinks.selectAll<SVGGElement, FlowGraphForcedLinkDatum>('.relationship').each(function (relationship) {

      const rel = d3.select<SVGGElement, FlowGraphForcedLinkDatum>(this);
      const outline = rel.select<SVGPathElement>('.outlined');

      outline.attr('d', (d: SimulationLinkDatum<FlowGraphForcedNodeDatum>) => {

        const center = { x: 0, y: 0 };
        const source: CoordinatedNodeDatum = d.source as CoordinatedNodeDatum;
        const target: CoordinatedNodeDatum = d.target as CoordinatedNodeDatum;

        const angle = getAngle(source, target);
        const u = unitaryVector(source, target);
        const n = unitaryNormalVector(source, target);

        const rotatedPointA1 = rotatePoint(center, {
          x: (source.nodeRadius + 1) * u.x - n.x,
          y: (source.nodeRadius + 1) * u.y - n.y,
        }, angle);
        const rotatedPointB2 = rotatePoint(center, {
          x: target.x - source.x - (target.nodeRadius + 7) * u.x - n.x - u.x * /*options.arrowSize*/4,
          y: target.y - source.y - (target.nodeRadius + 7) * u.y - n.y - u.y * /*options.arrowSize*/4,
        }, angle);

        if (relationship.startNode === relationship.endNode) {

          if (relationship.triggerType === `wait`) {
            return `
              M 0 ${source.nodeRadius}
              C 35,110 110,35 ${source.nodeRadius + 5}, 0
            `;
          } else {
            return `
              M 0 ${source.nodeRadius}
              C -35,110 -110,35 -${source.nodeRadius + 5}, 0
            `;
          }
        }

        const positioned = relationship as PositionedLinkDatum;
        const moveForwardLength = Math.abs(positioned.position / 2.5);

        return `M ${rotatedPointA1.x - 20} ${rotatedPointA1.y + positioned.position}
                L ${rotatedPointB2.x + moveForwardLength} ${rotatedPointB2.y + positioned.position}`;
      });
    });
  }

  /**
   * Defines if the arrow goes backside and the text should be rotated not to be upside-down.
   * @param d Link datum
   */
  function isMirrored(d: FlowGraphForcedLinkDatum): boolean {
    const angle = (getAngle(d.source as CoordinatedNodeDatum, d.target as CoordinatedNodeDatum) + 360) % 360;
    return angle > 90 && angle < 270;
  }

  /**
   * @private
   * Repositions link text
   * @param svgLinks D3 Selection of links container
   */
  function _tickRelationshipsTexts(svgLinks: D3SvgLinkGroupSelection) {
    svgLinks.selectAll<SVGGElement, FlowGraphForcedLinkDatum>('.relationship').each(function (relationship) {

      const rel = d3.select<SVGGElement, FlowGraphForcedLinkDatum>(this);
      const text = rel.select<SVGTextElement>('.text');

      text.attr('transform', d => {
        const source: CoordinatedNodeDatum = d.source as CoordinatedNodeDatum;
        const target: CoordinatedNodeDatum = d.target as CoordinatedNodeDatum;

        const angle = (getAngle(source, target) + 360) % 360;
        const mirror = isMirrored(d);
        const center = { x: 0, y: 0 };
        const n = unitaryNormalVector(source, target);
        const nWeight = mirror ? 2 : -3;
        const point = {
          x: (target.x - source.x) * 0.5 + n.x * nWeight,
          y: (target.y - source.y) * 0.5 + n.y * nWeight,
        };
        const rotatedPoint = rotatePoint(center, point, angle);

        if (source.id === target.id) {
          if (d.triggerType === `wait`) {
            return `translate(-30, 120) rotate(-40)`;
          } else {
            return `translate(-120, -30) rotate(50)`;
          }
        } else {
          const positioned = relationship as PositionedLinkDatum;
          const translateX = rotatedPoint.x + (mirror ? 100 : -100);
          const translateY = rotatedPoint.y + positioned.position + (mirror ? 12 : -12);

          return `translate(${translateX}, ${translateY}) rotate(${mirror ? 180 : 0})`;
        }
      });
    });

    svgLinks.selectAll<HTMLDivElement, FlowGraphForcedLinkDatum>('.relationship .relationship-label')
      .classed('mirror', d => {
        return isMirrored(d);
      });
  }

  return {
    convertToD3,
    updateLinks,
    tickLinks,
  };
}

import DeployerApi from '@/api/DeployerApi';
import DeploymentsApi from '@/api/DeploymentsApi';
import FlowsApi from '@/api/FlowsApi';
import { useBotStore } from '@/stores/index';
import { BotId, FlowId } from '@/types';
import { Deployment } from '@or-sdk/deployments';
import _ from 'lodash';
import { defineStore } from 'pinia';

type DeploymentsByBotMap = { [botId: BotId]: Deployment[]; };

const FETCH_PROJECTION = [
  'flowId',
  'data.alias',
  'data.flowVersion',
  'data.triggers',
  'dateModified',
  'data.role',
];

async function getFlowToDeploy(flowId: string) {
  return await FlowsApi.getFlow(flowId, {
    projection: [
      'id',
      'botId',
      'data.deploy.role',
    ],
  });
}

export const deploymentStore = defineStore('deployment', {
  state: () => {
    return {
      deploymentsByBot: {} as DeploymentsByBotMap,
    };
  },

  getters: {
    currentBotDeployments(): Deployment[] {
      const botId = useBotStore().currentBotId;
      return botId ? this.getDeploymentsByBotId(botId) : [];
    },
    getDeploymentsByBotId(state) {
      return (botId: BotId): Deployment[] => state.deploymentsByBot[botId];
    },
    getAllDeployments(state): Deployment[] {
      return _.flatMap(state.deploymentsByBot);
    },
  },

  actions: {
    async fetchDeploymentsForBot(botId: BotId): Promise<Deployment[]> {
      const deployments = await DeploymentsApi.listActiveDeploymentsByBotId(
        botId,
        FETCH_PROJECTION,
      );

      this.deploymentsByBot[botId] = deployments.items;

      return deployments.items;
    },

    async activateFlow(flowId: FlowId) {
      const flowToDeploy = await getFlowToDeploy(flowId);
      await DeployerApi.activateFlow(flowToDeploy);
      await this.fetchDeploymentsForBot(flowToDeploy.botId);
    },

    async deactivateFlow(flowId: FlowId, flowAlias: string) {
      const flowToDeploy = await getFlowToDeploy(flowId);
      await DeployerApi.deactivateFlow(flowToDeploy, flowAlias);
      await this.fetchDeploymentsForBot(flowToDeploy.botId);
    },
  },
});

export default deploymentStore;

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "or-status-footer w-full flex justify-between items-center px-lg py-sm gap-xl bg-background" }
const _hoisted_2 = { class: "grow typography-caption-regular text-outline" }
const _hoisted_3 = { class: "or-status-footer__actions flex gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "actions")
    ])
  ]))
}

import { AuthApi, AccountsApi, UsersApi } from '@/api';
import { AppPage } from '@/components';
import config from '@/config';
import { useAuth } from '@/stores';
import {
  ACCOUNTS_API_INJECTION_TOKEN,
  APP_CONFIG,
  AUTH_API_INJECTION_TOKEN,
  ExpirationOverlay,
  USERS_API_INJECTION_TOKEN,
} from '@onereach/auth-ui-module';
import { OrToastContainerV3 as OrToastContainer } from '@onereach/ui-components';
import { storeToRefs } from 'pinia';
import { defineComponent, provide } from 'vue';
import { useI18n } from 'vue-i18n';
import pkg from '../package.json';

export default defineComponent({
  components: {
    AppPage,
    ExpirationOverlay,
    OrToastContainer,
  },
  setup() {
    const { t } = useI18n();
    const authStore = useAuth();
    const {
      authorized: isAuthorized,
      username,
      identityProvider,
      token,
      cookiesAnotherUser,
      cookiesExpired,
      userId,
      multiUserId,
      accountId,
    } = storeToRefs(authStore);

    const {
      updateCookieExpiration,
      getUserNameForLockScreen,
    } = authStore;


    //provide api services to AuthPopover
    provide(AUTH_API_INJECTION_TOKEN, AuthApi);
    provide(ACCOUNTS_API_INJECTION_TOKEN, AccountsApi);
    provide(USERS_API_INJECTION_TOKEN, UsersApi);
    provide(APP_CONFIG, config);

    return {
      t,
      isAuthorized,
      multiUserId,
      cookiesAnotherUser,
      username,
      accountId,
      authUrl: config.AUTH_UI_URL,
      identityProvider,
      token,
      userId,
      cookiesExpired,
      updateCookieExpiration,
      getUserNameForLockScreen,
    };
  },
  data() {
    return {
      config,
      serviceTranslations: pkg.projectConfig.translations,
      lockScreenUsername: '',
    };
  },
  watch: {
    isAuthorized: {
      async handler(value): Promise<void> {
        if (value) {
          this.lockScreenUsername = await AuthApi.getUserNameForLockScreen({
            multiUserId: this.multiUserId,
            username: this.username,
            token: this.token,
          });
        }
      },
    },
  },
  async mounted(): Promise<void> {
    const unwatchAuth = this.$watch(
      () => {
        return this.isAuthorized && !!this.$route;
      },
      async (isAuthorizedOnRoute: boolean | null) => {
        if (isAuthorizedOnRoute) {
          let stopWatching;
          if (typeof this.$route?.meta?.initialize === 'function') {
            stopWatching = await this.$route.meta.initialize({ botId: this.$route?.params?.botId });
          }
          if (stopWatching) unwatchAuth();
        }
      },
      { immediate: true, deep: true });
  },
  errorCaptured(e) {
    console.error(e);
    return false;
  },
  methods: {
    cookiesRenewHandler(): void {
      AuthApi.updateCookieExpiration();
    },
  },
});

import { Sorting } from '@/types/OrSortingSelector';
import { defineStore } from 'pinia';

export const sortingStore = defineStore('sorting', {
  state: () => {
    return {
      /**
       * Sorting order for catalog items.
       */
      catalogSorting: {
        property: 'dateModified',
        order: 'desc',
      } as Sorting,
    };
  },

  actions: {
    setCatalogSorting(sorting: Sorting) {
      this.catalogSorting = sorting;
    },
  },
});

export default sortingStore;

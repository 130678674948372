
import {
  LoaderVariant,
  EmptyStateSize,
  OrLoaderV3 as OrLoader,
  OrEmptyStateV3 as OrEmptyState,
} from '@onereach/ui-components';
import { defineComponent, PropType } from 'vue';

export type MessagePageLoader = boolean | LoaderVariant | undefined;

export default defineComponent({
  name: 'OrMessagePage',
  components: {
    OrEmptyState,
    OrLoader,
  },
  props: {
    /**
     * Heading that appears on the message page.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Additional test such as a hint for a user with proposed next steps.
     */
    text: {
      type: String,
      default: '',
    },
    /**
     * Material icon name to display above the heading.
     */
    icon: {
      type: String,
      default: undefined,
    },
    /**
     * Path to the image to display above the heading.
     * Image height is scaled to 48px, width is undefined to preserve aspect ratio.
     */
    iconSrc: {
      type: String,
      default: undefined,
    },
    /**
     * If provided, then loader is being displayed on the message page.
     * Accepts booleans and OrLoader::variant values. `true` by default falls back to 'linear'.
     */
    loader: {
      type: [String, Boolean] as PropType<MessagePageLoader>,
      default: false,
    },
    /**
     * <code>OrEmptyState</code> size property. Passed to the child as is.
     */
    size: {
      type: String as PropType<EmptyStateSize>,
      default: EmptyStateSize.M,
    },
  },
  computed: {
    loaderVariant(): LoaderVariant | undefined {
      if (typeof this.loader === 'string') {
        return this.loader;
      } else if (this.loader === true) {
        return LoaderVariant.Linear;
      } else {
        return undefined;
      }
    },
  },
});

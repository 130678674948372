import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grow w-full flex justify-center items-center"
}
const _hoisted_2 = {
  key: 0,
  class: "my-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrLoader = _resolveComponent("OrLoader")!
  const _component_OrEmptyState = _resolveComponent("OrEmptyState")!

  return (_ctx.loader && _ctx.size === 'l')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OrLoader, {
          title: _ctx.title,
          description: _ctx.text,
          variant: _ctx.loaderVariant,
          class: "message-page_loader max-w-[432px] w-2/5"
        }, null, 8, ["title", "description", "variant"])
      ]))
    : (_openBlock(), _createBlock(_component_OrEmptyState, {
        key: 1,
        size: _ctx.size,
        heading: _ctx.title,
        text: _ctx.text,
        "image-src": _ctx.iconSrc
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.loader && _ctx.size === 'm')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_OrLoader, {
                    variant: _ctx.loaderVariant,
                    class: "message-page_loader"
                  }, null, 8, ["variant"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      }, 8, ["size", "heading", "text", "image-src"]))
}
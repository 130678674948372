import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrIcon = _resolveComponent("OrIcon")!
  const _component_OrButton = _resolveComponent("OrButton")!
  const _component_OrMenuItem = _resolveComponent("OrMenuItem")!
  const _component_OrMenu = _resolveComponent("OrMenu")!

  return (_ctx.sortLabels.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OrButton, {
          ref: "sortButtonRef",
          variant: "link",
          color: "inherit",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sortMenuRef.toggle()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getLabel(_ctx.selectedOption)) + " ", 1),
            (_ctx.sortMenuRef)
              ? (_openBlock(), _createBlock(_component_OrIcon, {
                  key: 0,
                  icon: _ctx.sortMenuRef.isOpen ? 'arrow_drop_up' : 'arrow_drop_down'
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512),
        _createVNode(_component_OrMenu, {
          ref: "sortMenuRef",
          trigger: _ctx.sortButtonRef?.root
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortLabels, (label) => {
              return (_openBlock(), _createBlock(_component_OrMenuItem, {
                key: label,
                selected: _ctx.isSelected(label),
                onClick: ($event: any) => (_ctx.onOptionSelected(label))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(label), 1)
                ]),
                _: 2
              }, 1032, ["selected", "onClick"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["trigger"])
      ]))
    : _createCommentVNode("", true)
}
import '@onereach/styles';

import i18n from '@/i18n';
import router from '@/router';
import { createApp } from 'vue';

import AppRoot from '@/AppRoot.vue';
import createPinia from '@/stores';

const app = createApp(AppRoot);

app.use(i18n);
app.use(createPinia());
app.use(router);

app.mount('#app');

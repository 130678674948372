import { createPinia as createPiniaOrigin } from 'pinia';
import { PiniaLogger } from 'pinia-logger';

import { LoadingHandlerPiniaPlugin } from './plugins';

export { default as useAuth } from './auth';
export { default as useBotStore } from './bot';
export { default as useDeploymentStore } from './deployment';
export { default as useFlowStore } from './flow';
export { default as useFlowGraphStore } from './flowGraph';
export { default as useSortingStore } from './sorting';
export { default as useTagStore } from './tag';

function createPinia() {
  return createPiniaOrigin()
    .use(PiniaLogger({
      expanded: false,
      disabled: process.env.NODE_ENV === 'production',
    }))
    .use(LoadingHandlerPiniaPlugin);
}

export default createPinia;

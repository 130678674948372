import { useGraphContainer } from '@/components/flowGraph/container';
import { useGraphLinks } from '@/components/flowGraph/links';
import { useGraphNodes } from '@/components/flowGraph/nodes';
import { useZoom } from '@/components/flowGraph/zoom';
import {
  D3SvgGroupSelection,
  D3SvgLinkGroupSelection,
  D3SvgNodeGroupSelection,
  D3SvgSelection,
  IFlowGraphLinkDatum,
  IFlowGraphNodeDatum, FlowGraphForcedLinkDatum, FlowGraphForcedNodeDatum,
  FlowGraphOptions,
  Zoomable, D3SvgNodeSelection, D3SvgLinkSelection,
} from '@/types';
import { ForceLink, Simulation } from 'd3';

export default class FlowGraphD3 implements Zoomable {
  selector: string;
  options: FlowGraphOptions;
  svg!: D3SvgSelection;
  svgGroup!: D3SvgGroupSelection;
  svgNodes!: D3SvgNodeGroupSelection;
  svgNode!: D3SvgNodeSelection;
  svgLinks!: D3SvgLinkGroupSelection;
  svgLink!: D3SvgLinkSelection;

  simulation?: Simulation<FlowGraphForcedNodeDatum, FlowGraphForcedLinkDatum>;
  containerHelper;
  nodeHelper;
  linkHelper;
  zoomHelper: any;
  dragHelper: any;
  constructor(selector: string,
              nodes: IFlowGraphNodeDatum[],
              links: IFlowGraphLinkDatum[],
              options: FlowGraphOptions) {
    this.selector = selector;
    this.options = options;
    this.containerHelper = useGraphContainer();
    this.nodeHelper = useGraphNodes();
    this.linkHelper = useGraphLinks();
    this.init(nodes, links);
  }
  init(nodes: IFlowGraphNodeDatum[], links: IFlowGraphLinkDatum[]) {
    const { svg, svgGroup, svgNodes, svgNode, svgLinks, svgLink } = this.containerHelper.appendGraph(this.selector);
    this.svg = svg;
    this.svgGroup = svgGroup;
    this.svgNodes = svgNodes;
    this.svgNode = svgNode;
    this.svgLinks = svgLinks;
    this.svgLink = svgLink;
    this.zoomHelper = useZoom(svgGroup);
    this.svg.call(this.zoomHelper.zoom);
    this.simulation = this.containerHelper.useForce(svg, svgNodes, svgLinks);
    this.simulation?.on('end', () => {
      this.zoomHelper.zoomToFit();
    });
    this.update(nodes, links);
  }
  update(nodes: IFlowGraphNodeDatum[], links: IFlowGraphLinkDatum[]) {
    this.simulation!.stop();
    const oldNodes = this.svgNode.data();
    const forcedNodes = this.nodeHelper.convertToD3(nodes, oldNodes);
    this.svgNode = this.nodeHelper.updateNodes(this.svgNodes, forcedNodes);
    const oldLinks = this.svgLink.data();
    const forcedLinks = this.linkHelper.convertToD3(links, oldLinks, forcedNodes);
    this.svgLink = this.linkHelper.updateLinks(this.svgLinks, forcedLinks);
    this.simulation!.nodes(forcedNodes);
    this.simulation!.force<ForceLink<FlowGraphForcedNodeDatum, FlowGraphForcedLinkDatum>>('link')?.links(forcedLinks);
    this.simulation!.alpha(1).restart();
  }
  zoomIn() {
    this.zoomHelper.zoomIn();
  }
  zoomOut() {
    this.zoomHelper.zoomOut();
  }
  zoomToFit() {
    this.zoomHelper.zoomToFit();
  }
}

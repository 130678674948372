import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-sm" }
const _hoisted_2 = { class: "grow" }
const _hoisted_3 = { class: "flex justify-end gap-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrIcon = _resolveComponent("OrIcon")!
  const _component_OrButton = _resolveComponent("OrButton")!
  const _component_OrCheckbox = _resolveComponent("OrCheckbox")!
  const _component_OrModal = _resolveComponent("OrModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_OrButton, {
      variant: "text",
      onClick: _ctx.onTrigger
    }, {
      default: _withCtx(() => [
        _createVNode(_component_OrIcon, { icon: "add" }),
        _createTextVNode(" " + _toDisplayString(_ctx.t('OrObjectHeader.tags.addBtn')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_OrModal, {
      ref: "tagDialogRef",
      size: "s"
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('OrObjectHeader.tags.dialogTitle')), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_OrButton, {
            variant: "outlined",
            onClick: _ctx.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('common.button.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_OrButton, { onClick: _ctx.onApply }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('common.button.apply')), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.items as Tag[]), (tag) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tag.id,
            class: "flex mr-md"
          }, [
            _createVNode(_component_OrCheckbox, {
              modelValue: _ctx.dirtyModel,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dirtyModel) = $event)),
              class: "grow",
              value: tag.id
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_OrIcon, {
                    icon: "circle",
                    variant: "filled",
                    style: _normalizeStyle({ color: tag.data.color })
                  }, null, 8, ["style"]),
                  _createElementVNode("div", _hoisted_2, _toDisplayString(tag.data.label), 1)
                ])
              ]),
              _: 2
            }, 1032, ["modelValue", "value"])
          ]))
        }), 128))
      ]),
      _: 1
    }, 512)
  ], 64))
}
import config from '@/config';
import { Auth } from '@onereach/sdk';

const instance = new Auth({
  cookieDomain: config.COOKIE_DOMAIN,
  cookiePrefix: config.COOKIE_NAME_PREFIX_NEW,
  authUrl: `${config.SDK_API_URL}/auth/token`,
  allowGuestLogin: Boolean(config.GUEST_LOGIN),
  userCookieName: `${config.COOKIE_NAME_PREFIX}_name`,
  userExpireCookieName: `${config.COOKIE_NAME_PREFIX}_user_expire`,
  multiUserCookieName: `${config.COOKIE_NAME_PREFIX}_multi`,
  expireInLong: `${config.COOKIE_EXPIRATION_LONG}`,
  expireInShort: `${config.COOKIE_EXPIRATION_SHORT}`,
  sdkApiUrl: `${config.SDK_API_URL}`,
  allowIframe: Boolean(config.ALLOW_IFRAME),
});

export default instance;

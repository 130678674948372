import { FlowsApi } from '@/api';
import { STEP_TYPE } from '@/components/flowGraph/stepTypes';
import { useBotStore, useDeploymentStore, useSortingStore } from '@/stores';
import { BotId, ExistingFlow, FlowId } from '@/types';
import { FlowsByBotIdMap } from '@/types/catalog';
import { Sorting } from '@/types/OrSortingSelector';
import { Flow, Step } from '@or-sdk/deployer';
import { ListFlowsParams } from '@or-sdk/flows';
import _ from 'lodash';
import { defineStore } from 'pinia';

const FETCH_PROJECTION_LITE = [
  'id',
  'botId',
  'data.label',
  'data.color',
  'dateModified',
];

const FETCH_PROJECTION_FULL = [
  'id',
  'botId',
  'data.label',
  'data.color',
  'data.trees.main.steps.id',
  'data.trees.main.steps.flowId',
  'data.trees.main.steps.label',
  'data.trees.main.steps.type',
  'data.trees.main.steps.data.flowId',
  'data.trees.main.steps.data.useCustomFlowName',
  'data.trees.main.steps.data.customFlowName',
  'data.trees.main.steps.data.isUseCustomSubflow',
  'data.trees.main.steps.data.customSubFlowName',
  'data.trees.main.steps.data.flowIdToTrigger',
  'dateModified',
];

/**
 * Removes redundant data from the flow model
 * @param flow Fetched flow with extra trees and steps
 */
function cleanUpFlowData(flow: ExistingFlow) {
  // fixme: backend returns all trees; remove after it fixed
  flow.data.trees = _.pick(flow.data.trees, 'main');

  removeNonTriggerSteps(flow);
}

/**
 * Removes non-trigger steps data.
 * Currently, the step is defined as trigger if its type has one of the certain hardcoded values.
 * @param flow
 */
function removeNonTriggerSteps(flow: ExistingFlow): ExistingFlow {
  if (flow.data.trees?.main?.steps) {
    flow.data.trees.main.steps = flow.data.trees.main.steps.filter(
      (step: Step) => step.type && STEP_TYPE.ANY.includes(step.type)
    );
  }
  return flow;
}


function sortFlows(flows: ExistingFlow[], sorting: Sorting) {
  switch (sorting.property) {
    case 'dateModified':
      return _.orderBy(flows, ['dateModified'], [sorting.order]);
    case 'dateActivated': {
      const byName = sortByName(flows);
      const deploymentsByDate =
        _.orderBy(useDeploymentStore().getAllDeployments, ['dateModified'], [sorting.order]);
      const activated: ExistingFlow[] = [];
      deploymentsByDate.forEach(d => {
        const activatedFlow = byName.find(flow => d.flowId === flow.id);
        if (activatedFlow) {
          _.pull(byName, activatedFlow);
          activated.push(activatedFlow);
        }
      });
      return [
        ...activated,
        ...byName,
      ];
    }
    case 'name':
    default:
      return _.orderBy(flows, [(flow) => flow.data.label.toLowerCase()], [sorting.order]);
  }
}


function sortByName(flows: ExistingFlow[]): ExistingFlow[] {
  return _.sortBy(flows, (flow) => flow.data.label.toLowerCase());
}

/**
 * Fetches all flows whose name contains given search phrase. Case-insensitive.
 * @param searchTerm String to search in the flow's name.
 */
async function _findAllFlows(searchTerm?: string): Promise<ExistingFlow[]> {
  let query;
  if (searchTerm) {
    query = {
      'data.label': { iLike: `%${searchTerm}%` },
    };
  }
  const flowList = await FlowsApi.listFlows(undefined,
    {
      query,
      order: `"data"->'label'`,
      projection: FETCH_PROJECTION_LITE,
    } as ListFlowsParams,
  );
  return sortByName(flowList.items as ExistingFlow[]);
}

/**
 * Fetches flows with given bot id.
 * @param botId Identifier of a bot to load flows for.
 */
async function _fetchFlowsByBotId(botId: string): Promise<ExistingFlow[]> {
  const flowList = await FlowsApi.listFlows(
    botId,
    {
      query: { botId: botId },
      order: `"data"->'label'`,
      projection: FETCH_PROJECTION_LITE,
    } as ListFlowsParams,
  );
  return sortByName(flowList.items as ExistingFlow[]);
}

/**
 * Fetches flows with given bot id.
 * @param botId Identifier of a bot to load flows for.
 */
async function _fetchFlowsWithStepsByBotId(botId: string): Promise<ExistingFlow[]> {
  const flowList = await FlowsApi.listFlows(
    botId,
    {
      query: { botId: botId },
      stepsQuery: {
        type: {
          ne: 'empty',
        },
      },
      order: `"data"->'label'`,
      projection: FETCH_PROJECTION_FULL,
    } as ListFlowsParams, // todo: tweak the type and remove casting
  );
  const flows = sortByName(flowList.items as ExistingFlow[]);
  flows.forEach(cleanUpFlowData);
  return flows;
}

export const flowStore = defineStore('flow', {
  state: () => {
    return {
      /**
       * Map of flow arrays by the botId. Each array contains all flows for the corresponding bot.
       */
      allFlowsByBot: {} as FlowsByBotIdMap,
      /**
       * Map of flow arrays by the botId. Each array contains a subset of flows that satisfies current search filter.
       */
      filteredFlowsByBot: {} as FlowsByBotIdMap,
      /**
       * Flow selected for displaying its details.
       */
      currentFlow: null as Flow | null,
      /**
       * Search term to filter flows.
       */
      searchTerm: '' as string | undefined,
    };
  },

  getters: {
    currentBotFlows(): ExistingFlow[] {
      const botId = useBotStore().currentBotId;
      return botId ? this.getAllFlowsByBotId(botId) : [];
    },
    /**
     * Returns all bot's flows. Used by bot details page.
     * @param state
     */
    getAllFlowsByBotId(state) {
      return (botId: BotId): ExistingFlow[] => state.allFlowsByBot[botId];
    },
    /**
     * Returns filtered and sorted bot's flows. Used by catalog.
     * @param state
     */
    getFilteredFlowsByBotId(state) {
      /**
       * @param botId Bot identifier
       * @param botMatches States that bot name matcher search criteria. All flows returned if true. (FLOW-293)
       */
      function getter(botId: BotId, botMatches: boolean): ExistingFlow[] {
        const flows =
          state.searchTerm && !botMatches ? state.filteredFlowsByBot[botId] : state.allFlowsByBot[botId];
        const sorting = useSortingStore().catalogSorting;
        return sortFlows(flows, sorting);
      }

      return getter;
    },
  },

  actions: {
    /**
     * Fetches brief flow data the given bot. Stores result in the bot store. Used by the catalog.
     * @param botId Bot identifier
     */
    async fetchAllBriefFlowsForBot(botId: BotId) {
      // if (!this.searchTerm) this.filteredFlowsByBot[botId] = [];   // why?
      const botStore = useBotStore();
      botStore.setFlowsAreLoading(botId, true);
      try {
        const cachedFlows = this.allFlowsByBot[botId];
        const flows = cachedFlows || await _fetchFlowsByBotId(botId);
        if (!cachedFlows) this.allFlowsByBot[botId] = flows;
      } finally {
        botStore.setFlowsAreLoading(botId, false);
      }
    },

    /**
     * Fetches full flow data including steps for the given bot. Stores result in the bot store.
     * Used by bot details page.
     * @param botId Bot identifier
     */
    async fetchAllFullFlowsForBot(botId: BotId) {
      const cachedFlows = this.allFlowsByBot[botId];
      // ignore cache if steps are needed. to be sure the data is up-to-date
      const flows = await _fetchFlowsWithStepsByBotId(botId);
      if (!cachedFlows) this.allFlowsByBot[botId] = flows;
    },

    /**
     * Selects the flow by bot and flow ids. Loads bot's flows if not loaded.
     * todo: implement routing case when botId is not provided
     * @param botId
     * @param flowId
     */
    async setCurrentFlow(botId: BotId, flowId: FlowId) {
      await this.fetchAllBriefFlowsForBot(botId);
      const flows = this.allFlowsByBot[botId];
      const flow = _.find(flows, { id: flowId });
      this.currentFlow = flow || null;
    },

    async searchFlows(searchTerm: string) {
      const foundFlows = await _findAllFlows(searchTerm);
      this.filteredFlowsByBot = _.groupBy(foundFlows, 'botId');
    },
  },
});

export default flowStore;

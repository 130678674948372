// todo: get rid of this hardcode by introducing isTriggerStep in DB;
//  migration is needed for these and inherited step templates

const triggerAndProceedStepTypes = [
  '670d93ea-cfa8-4a02-a224-e70d6f91e4cc',
  'efc014cb-d50b-4bf7-8799-7a9ac27287ef',
];

const triggerAndWaitStepTypes = [
  '05686b75-9048-4162-808e-e1153fe76b79',
  '9187743f-a1f7-4286-9120-83cbcaaae61e',
];

const waitForTriggerFromAnotherFlowStepTypes = [
  'd51593b3-7c89-44bc-9961-d7f08922ca14',
  '724f26ab-64e5-4c8c-85d6-bbffa319ff8d',
];

export const STEP_TYPE = {
  TRIGGER_AND_PROCEED: triggerAndProceedStepTypes,
  TRIGGER_AND_WAIT: triggerAndWaitStepTypes,
  WAIT_FOR_TRIGGER_FROM_ANOTHER_FLOW: waitForTriggerFromAnotherFlowStepTypes,
  ANY: waitForTriggerFromAnotherFlowStepTypes.concat(
    triggerAndWaitStepTypes,
    triggerAndProceedStepTypes,
  ),
};

export const isWaitForTriggerFromAnotherFlowStep =
  (type?: string) => type ? STEP_TYPE.WAIT_FOR_TRIGGER_FROM_ANOTHER_FLOW.includes(type) : false;

export const isTriggerAndProceedStep =
  (type?: string) => type ? STEP_TYPE.TRIGGER_AND_PROCEED.includes(type) : false;

export const isTriggerAndWaitStep =
  (type?: string) => type ? STEP_TYPE.TRIGGER_AND_WAIT.includes(type) : false;


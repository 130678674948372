import { PiniaPluginContext } from 'pinia';
import { computed, ref } from 'vue';

function LoadingHandlerPiniaPlugin({ store }: PiniaPluginContext) {
  const activeLoaders = ref<string[]>([]);
  const isLoading = computed(() => {
    return (actionName?: string) => {
      if (actionName) {
        return activeLoaders.value.includes(actionName);
      } else {
        return activeLoaders.value.length > 0;
      }
    };
  });

  store.$onAction(({ name, after, onError }) => {
    activeLoaders.value.push(name);

    const tryRemoveLoader = () => {
      const index = activeLoaders.value.indexOf(name);
      if (index !== -1) {
        activeLoaders.value.splice(index, 1);
      }
    };

    after(tryRemoveLoader);
    onError(tryRemoveLoader);
  });

  return {
    isLoading,
    activeLoaders,
  };
}

export default LoadingHandlerPiniaPlugin;

import { NODE_RADIUS } from '@/components/flowGraph/constants';
import {
  isTriggerAndProceedStep,
  isTriggerAndWaitStep,
  isWaitForTriggerFromAnotherFlowStep,
} from '@/components/flowGraph/stepTypes';
import { useDeploymentStore, useFlowStore } from '@/stores/index';
import {
  DeployedFlow,
  ExistingFlow,
  FlowGraphLink,
  FlowGraphNode,
  FlowId, IFlowGraphLinkDatum, IFlowGraphNodeDatum,
  TriggerStep,
  TriggerStepData,
  TriggerType,
} from '@/types';
import { Flow, Step } from '@or-sdk/deployer';
import { Deployment } from '@or-sdk/deployments';
import _ from 'lodash';
import { defineStore } from 'pinia';
import { v4 as uuid } from 'uuid';

/**
 * Creates trigger step data object for given flow and step.
 * @param step
 * @param flow
 * @param triggerType
 */
function buildTriggerStepObject(flow: ExistingFlow, step: Step, triggerType?: TriggerType): TriggerStep {
  return {
    botId: flow.botId,
    flowId: flow.id,
    stepId: step.id,
    flowLabel: _.get(flow, 'data.label', 'No name'),
    flowColor: _.get(flow, 'data.color', '#fff'),
    // stepLabel: step.label,
    // stepDescription: step.description,
    stepData: step.data,
    stepType: step.type,
    triggerType: triggerType,
  };
}
//todo: validate and use or remove
function buildStepDataForConnection(step: TriggerStep, triggeredFlowId: FlowId): TriggerStepData {
  return {
    botId: step.botId,
    flowId: step.flowId,
    stepId: step.stepId,
    flowLabel: step.flowLabel,
    // stepLabel: step.label,
    // stepDescription: step.description,
    // flowParameters: _.map(step.stepData?.flowParameters, param => _.omit(param, 'vforkey')),
    triggeredFlowId,
  };
}

/**
 * @deprecated The label is defined but not used on UI. Copied as is from legacy.
 * Trigger type is never an object.
 * todo: Double check usages and remove.
 * @param triggerType
 */
/*
function buildConnectionLabel(triggerType: TriggerType): string {
  if (!_.isObject(triggerType)) {
    return `TRIGGER AND ${_.toUpper(triggerType)} (1)`;
  } else {
    // UNREACHABLE

    let label = 'TRIGGER AND ';
    //
    // _.forEach(_.toPairs(triggerType), ([type, value], index) => {
    //   const prefix = index > 0 ? '/' : '';
    //
    //   label += ` ${prefix} ${_.toUpper(type)} (${_.size(value.steps)})`;
    // });
    //
    return label;
  }
}
*/


/**
 * Analyzes flows to find trigger or triggered steps.
 * todo: introduce trigger flag in DB
 * @param flows Flows with step information
 */
function getTriggerStepsFromFlows(flows: ExistingFlow[] = []) {
  const flowsWithTriggerSteps: ExistingFlow[] = [];
  const flowsWithWaitForTriggerSteps: ExistingFlow[] = [];

  const triggerAnotherFlowSteps: TriggerStep[] = [];
  const waitForTriggerFromAnotherFlowSteps: TriggerStep[] = [];

  flows.forEach(flow => {
    const flowSteps = _.get(flow, 'data.trees.main.steps', []);

    flowSteps.forEach(step => {
      if (isTriggerAndProceedStep(step.type)) {
        const stepObj = buildTriggerStepObject(flow, step, 'proceed');

        triggerAnotherFlowSteps.push(stepObj);
        flowsWithTriggerSteps.push(flow);
      } else if (isTriggerAndWaitStep(step.type)) {
        const stepObj = buildTriggerStepObject(flow, step, 'wait');

        triggerAnotherFlowSteps.push(stepObj);
        flowsWithTriggerSteps.push(flow);
      } else if (isWaitForTriggerFromAnotherFlowStep(step.type)) {
        const stepObj = buildTriggerStepObject(flow, step);

        waitForTriggerFromAnotherFlowSteps.push(stepObj);
        flowsWithWaitForTriggerSteps.push(flow);
      }
    });
  });

  return {
    // callers
    flowsWithTriggerSteps,
    triggerAnotherFlowSteps,

    // waiters
    flowsWithWaitForTriggerSteps,
    waitForTriggerFromAnotherFlowSteps,
  };
}

/**
 * Removes protocol part from the event name
 * @param eventName
 */
const normalizeFlowEventName = (eventName: string): string => eventName.split('/').slice(1).join('/');

/**
 * Removes spaces and quotes from trigger value
 * @param value
 */
const normalizeTriggerValue = (value: string) => _.trim(value, '"\' ');

/**
 * Gets trigger id, taking into account custom subflows.
 * @param step Trigger step
 */
const getStepTriggerId = (step: TriggerStep): FlowId => {
  const isUseCustomSubflow = _.get(step, 'stepData.isUseCustomSubflow', false);
  const flowId = _.get(step, 'stepData.flowId', '') as FlowId;

  if (isUseCustomSubflow || normalizeTriggerValue(flowId as FlowId) === 'customSubFlowName') {
    const customSubflowName = _.get(step, 'stepData.customSubFlowName', '');
    return normalizeTriggerValue(customSubflowName as string);
  }

  return _.get(step, 'stepData.flowIdToTrigger', flowId) as FlowId;
};


/**
 * Combines flows and deployments.
 * todo: should the backend do this?
 * @param flows Array of flows of the particular bot
 * @param deployments Array of deployments performed over some of those flows
 */
export function mergeDeploymentsWithFlows(flows: ExistingFlow[] = [],
                                          deployments: Deployment[] = []): DeployedFlow[] {
//  // THE ONLY NOT MOVED PIECE
//  if (!hiddenFlowsVisible) {
//    flows = _.reject(flows, { data: { isHidden: true } });
//  }

  // todo: get rid of repetitive call
  const { triggerAnotherFlowSteps } = getTriggerStepsFromFlows(flows);

  return flows.map(flow => {
    const deployment = _.find(deployments, { flowId: flow.id });

    const flowTriggerId = _.get(deployment, 'data.triggers[0].params.name', '');
    const normalizedTriggerId = normalizeFlowEventName(flowTriggerId);

    const parent = _.find(triggerAnotherFlowSteps, step => {
      const triggerId = getStepTriggerId(step);

      return normalizedTriggerId === triggerId;
    });

    return <DeployedFlow> {
      ...flow,
      parentId: parent ? parent.flowId : null,
      data: {
        ...flow.data,
        triggers: _.get(deployment, 'data.triggers', []),
        tags: _.get(flow, 'tags', []),
        deployment: {
          flowVersion: _.get(deployment, 'data.flowVersion'),
          dateModified: _.get(deployment, 'dateModified'),
        },
      },
    };
  });
}

const isEntryPointFlow = (flow: Flow, connections: FlowGraphLink[]) => {
  const outConnection = _.find(connections, { startNode: flow.id });
  const inConnection = _.find(connections, { endNode: flow.id });

  return Boolean(!inConnection && outConnection);
};

export const setConnection = (
  connections: FlowGraphLink[],
  { step, flowId, customTrigger = false }: { step: TriggerStep; flowId: FlowId; customTrigger?: boolean; }
) => {
  const startNode = step.flowId;
  const triggerType = step.triggerType;
  const endNode = flowId;

  const existingConnection = _.find(connections, { startNode, endNode, triggerType });

  if (existingConnection) {
    existingConnection.triggers.push(buildStepDataForConnection(step, flowId));
    // existingConnection.label = buildConnectionLabel(step.triggerType);
  } else {
    connections.push({
      id: uuid(),
      // label: buildConnectionLabel(step.triggerType),
      triggerType,
      customTrigger,
      startNode,
      endNode,
      triggers: [buildStepDataForConnection(step, flowId)],
    });
  }
};

const hasMergeField = (value: string): boolean => {
  // eslint-disable-next-line max-len
  const mergeFieldRegex = /await\s+this\.mergeFields\['(#?\w*?)'\]\.get\(.*?\)|\$\{[^}]*\}|this(\.session)?\.get(Shared|Global)?\(.*?\)|this\.(helpers|config|error)(\.[a-zA-Z_$][\w$])?/g;
  return _.isString(value) && mergeFieldRegex.test(value);
};

export const flowGraphStore = defineStore('flowStore', {
  state: () => {
    return {
      nodesOld: [] as FlowGraphNode[],
      linksOld: [] as FlowGraphLink[],
    };
  },

  getters: {
    /**
     * Combines flows and deployments, building trigger information blocks.
     * The logic comes from Legacy Flow Builder helpers::mergeDeploymentsWithFlows method.
     */
    deployedFlows() {
      const flows = useFlowStore().currentBotFlows;
      const deployments = useDeploymentStore().currentBotDeployments;

      return mergeDeploymentsWithFlows(flows, deployments);
    },

    nodes(): IFlowGraphNodeDatum[] {
      return this.nodesOld.map(node => ({
        id: node.id!,
        label: node.label,
        nodeRadius: node.nodeRadius,
        color: node.properties.color,
        activated: !node.deactivated,
        selected: false,
      }));
    },
    links(): IFlowGraphLinkDatum[] {
      return this.linksOld.map(link => ({
        id: link.id,
        startNode: link.startNode,
        endNode: link.endNode,
        triggerType: link.triggerType,
        customTrigger: link.customTrigger,
        triggerCounter: link.triggers.length,
      } as IFlowGraphLinkDatum));
    },
  },

  actions: {
    /**
     * Creates D3 node data objects from the flows.
     * @param flows
     * @param additionalNodes
     * @param links
     * @param waitForTriggerFromAnotherFlowSteps
     */
    transformFlowsToNodes(flows: DeployedFlow[],
                          additionalNodes: FlowGraphNode[],
                          links: FlowGraphLink[],
                          waitForTriggerFromAnotherFlowSteps: TriggerStep[]) {
      const transformedFlows = flows.map(flow => {
        const isEntryPoint = isEntryPointFlow(flow, links);
        const nodeRadius = isEntryPoint ? NODE_RADIUS.ENTRY_POINT : NODE_RADIUS.DEFAULT;
        const deactivated = !_.size(flow.data.triggers);
        const waitForTriggerStep = _.find(waitForTriggerFromAnotherFlowSteps, { flowId: flow.id });
        const expectedParameters = _.get(waitForTriggerStep, 'stepData.parameters', []);

        return <FlowGraphNode> {
          id: flow.id,
          label: _.get(flow, 'data.label') || 'No name',
          nodeRadius,
          deactivated,
          entryPoint: isEntryPoint,
          properties: {
            isHidden: _.get(flow, 'data.isHidden', []),
            tags: _.get(flow, 'data.tags', []),
            botId: _.get(flow, 'botId', null),
            description: _.get(flow, 'data.description') || '',
            color: _.get(flow, 'data.color') || '#fff',
            expectedParameters,
            triggers: _.get(flow, 'data.triggers') || [],
            version: _.get(flow, 'version'),
            deployment: _.get(flow, 'data.deployment'),
          },
        };
      });

      this.nodesOld = [...transformedFlows, ...additionalNodes];
    },

    /**
     * Creates D3 links data objects from trigger steps.
     * @param triggerAnotherFlowSteps
     * @param waitForTriggerFromAnotherFlowSteps
     */
    transformTriggerStepsToConnections(triggerAnotherFlowSteps: TriggerStep[],
                                       waitForTriggerFromAnotherFlowSteps: TriggerStep[]) {

      const accumulator: {
        links: FlowGraphLink[];
        additionalNodes: FlowGraphNode[];
      } = {
        links: [],
        additionalNodes: [],
      };

      return _.reduce(
        triggerAnotherFlowSteps,
        (memo, step) => {
          const triggerId = getStepTriggerId(step);

          const waitForTriggerStepOnFlow = _.find(waitForTriggerFromAnotherFlowSteps, waitForTriggerStep => {
            const useCustomFlowName = _.get(waitForTriggerStep, 'stepData.useCustomFlowName');

            if (useCustomFlowName) {
              const customFlowName = _.get(waitForTriggerStep, 'stepData.customFlowName', '');
              return triggerId === customFlowName;
            }

            const flowId = _.get(waitForTriggerStep, 'flowId');
            const flowIdToTrigger = _.get(waitForTriggerStep, 'stepData.flowIdToTrigger');

            return triggerId === flowId || triggerId === flowIdToTrigger;
          });

          if (!hasMergeField(triggerId) && !waitForTriggerStepOnFlow) {
            return memo;
          }

          if (!waitForTriggerStepOnFlow) {
            const existingCustomTriggerId = _.find(memo.additionalNodes, { triggerId });

            if (existingCustomTriggerId) {
              setConnection(memo.links, { step, flowId: existingCustomTriggerId.id!, customTrigger: true });
            } else {
              const newNodeId = uuid();

              memo.additionalNodes.push({
                id: newNodeId,
                triggerId,
                customTrigger: true,
                label: 'Custom trigger', //todo: i18n
                nodeRadius: 44,
                deactivated: true,
              } as FlowGraphNode);

              setConnection(memo.links, { step, flowId: newNodeId, customTrigger: true });
            }

            return memo;
          }

          setConnection(memo.links, { step, flowId: waitForTriggerStepOnFlow.flowId });

          return memo;
        },
        accumulator
      );
    },

    prepareGraphData() {
      const mergedFlowsWithTriggerNames = this.deployedFlows;
      const {
        triggerAnotherFlowSteps,
        waitForTriggerFromAnotherFlowSteps,
      }: {
        // todo: move me to types
        triggerAnotherFlowSteps: TriggerStep[];
        waitForTriggerFromAnotherFlowSteps: TriggerStep[];
      } = getTriggerStepsFromFlows(mergedFlowsWithTriggerNames);

      const {
        links,
        additionalNodes,
      } = this.transformTriggerStepsToConnections(
        triggerAnotherFlowSteps,
        waitForTriggerFromAnotherFlowSteps,
      );

      this.linksOld = links;

      this.transformFlowsToNodes(
        mergedFlowsWithTriggerNames,
        additionalNodes,
        links,
        waitForTriggerFromAnotherFlowSteps,
      );
    },

  },
});

/*
  selectedNodeId: undefined as string | undefined,
  actions: {
    setSelectedNodeId() {},
  },
*/

export default flowGraphStore;


import OrTagSelector from '@/components/library/OrTagSelector.vue';
import { useTagStore } from '@/stores';
import {
  OrTagV3 as OrTag,
} from '@onereach/ui-components';
import { useVModel } from '@vueuse/core';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onBeforeMount, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

/**
 * Page header for the objects like bots, flows, etc.
 * Includes object's icon/avatar, content section for the title(s) and tags, and action toolbar.
 */
export default defineComponent({
  name: 'OrObjectHeader',
  components: {
    OrTagSelector,
    OrTag,
  },
  props: {
    /**
     * Object title. The property is ignored if `title` slot is populated.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Object description. The property is ignored if `description` slot is populated.
     */
    description: {
      type: String,
      default: '',
    },
    /**
     * Object tags' identifiers. The header control encapsulates tag management so only ids are needed.
     */
    tags: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const tagStore = useTagStore();

    onBeforeMount(() => {
      tagStore.fetchAll();
    });

    const { allTags } = storeToRefs(tagStore);

    const tagsModel = useVModel(props, 'tags', emit);

    /**
     * Tag objects corresponding to the assigned id list passed via `tags` prop.
     */
    const assignedTags = computed(() => allTags.value.filter(t => t.id && props.tags?.includes(t.id)));

    function unassign(tagId: string) {
      _.remove(tagsModel.value, id => id === tagId);
    }

    return {
      t,
      allTags,
      assignedTags,
      tagsModel,
      // methods
      unassign,
    };
  },
});

import Graph from '@/components/legacy/graph/graph';
import {
  D3SvgNodeEnterSelection,
  D3SvgNodeGroupSelection,
  D3SvgNodeSelection,
  FlowGraphForcedNodeDatum,
  IFlowGraphNodeDatum,
} from '@/types';
import { FlowGraphNodeDatum } from '@/types/FlowGraphNodeDatum';
import { BaseType } from 'd3';
import _ from 'lodash';

/**
 * Helper methods for manipulating flow graph nodes
 */
export function useGraphNodes() {
  /**
   * Enrich business data object with properties from D3 Simulation. Ensures to re-use existing objects
   * @param newNodes
   * @param oldNodes
   */
  function convertToD3(newNodes: IFlowGraphNodeDatum[], oldNodes: IFlowGraphNodeDatum[]): FlowGraphForcedNodeDatum[] {
    return newNodes.map(node => {
      const existingNode = _.find(oldNodes, { id: node.id });
      if (existingNode) Object.assign(existingNode, node);
      return existingNode || new FlowGraphNodeDatum(node);
    });
  }

  /**
   * Reconstructs D3 DOM using d3.Selection.join, by adding/updating/removing nodes.
   * Requires consistent datum id to update correctly.
   * @param svgNodes D3 selection of nodes group element
   * @param nodeData Updated nodes data
   */
  function updateNodes(svgNodes: D3SvgNodeGroupSelection, nodeData: IFlowGraphNodeDatum[]): D3SvgNodeSelection {
    const forcedData: FlowGraphForcedNodeDatum[] = nodeData;

    return (svgNodes.selectAll('.node') as D3SvgNodeSelection)
      .data(forcedData, d => d.id)
      .join(
        enter => appendNode(enter),
        update => update,
        exit => exit.remove()
      );
  }

  /**
   * Appends new node group element and its content
   * @param enter D3 enter selection - new data records that require DOM elements creation
   */
  function appendNode(enter: D3SvgNodeEnterSelection): D3SvgNodeSelection {
    const node = enter.append('g')
      .classed('node', true)
      .classed(' node-deactivated', d => !d.activated)
    ;

    _appendRing(node); // IRL bigger circle below the body
    _appendBody(node);
    _appendColor(node);
    _appendText(node);

    return node;
  }

  /**
   * @private
   * Appends body circle to the node group
   * @param node Current node group
   */
  function _appendBody(node: D3SvgNodeSelection) {
    return node.append('circle')
      .attr('class', 'node-body')
      .attr('r', d => d.nodeRadius)
      .append('title').text(d => d.label);
  }

  /**
   * @private
   * Appends selection ring to the node group
   * @param node Current node group
   */
  function _appendRing(node: D3SvgNodeSelection) {
    return node.append('circle')
      .attr('class', 'node-ring')
      .attr('r', d => d.nodeRadius + 5);
  }

  /**
   * @private
   * Appends flow color circle to the node group
   * @param node Current node group
   */
  function _appendColor(node: D3SvgNodeSelection) {
    return node.append('circle')
      .attr('class', 'node-color')
      .attr('r', d => d.nodeRadius)
      .attr('style', d => `stroke: ${d.color}`)
      // todo: find out why legacy hid custom trigger color ring
      //.attr('style', d =>
      //  `${d.customTrigger || d.color === `#fff` ? 'display: none;' : ''}stroke: ${d.color || `#fff`}`)
    ;
  }

  /**
   * @private
   * Appends text label to the node group
   * @param node Current node group
   */
  function _appendText(node: D3SvgNodeSelection) {
    return node.append('text')
      .attr('class', 'label')
      .attr('x', `0px`)
      .attr('y', `0px`)
      .attr('font-size', () => '14px')
      // todo: port text routines
      .each(Graph.prototype.wrapText)
      .append('title').text(d => d.label);
  }

  /**
   * Moves nodes to coordinates provided by d3 force simulation
   * @param svgNodes D3 selection of nodes group element
   */
  function tickNodes(svgNodes: D3SvgNodeGroupSelection) {
    svgNodes.selectAll<BaseType, {x: number; y: number; }>('.node')
      .attr('transform', d => `translate(${d.x}, ${d.y})`);
  }

  return {
    convertToD3,
    updateNodes,
    tickNodes,
  };
}

import { createI18n, I18nOptions } from 'vue-i18n';

import en from './locales/en.json';

const datetimeFormats: I18nOptions['datetimeFormats'] = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
  },
};
const messages = {
  en,
};
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  datetimeFormats,
  legacy: false,
  globalInjection: true,
});

export default i18n;

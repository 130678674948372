import { Deployer } from '@or-sdk/deployer';
import _ from 'lodash';
import config from '../config';
import { useAuth } from '@/stores';

const instance = new Deployer({
  deployerUrl: `${config.DEPLOYER_URL}`,
  token: () => {
    return `USER ${_.get(useAuth(), 'token')}`;
  },
});

export default instance;

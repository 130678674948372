import { useBotStore } from '@/stores';
import { RouteRecordRaw } from 'vue-router';

async function _fetchBots(botId?: string) {
  const { fetchAllBots, appInited } = useBotStore();
  // todo: process flowId route
  if (!appInited) await fetchAllBots(botId);
}

async function initializeBots(params: {[key: string]: any;} = {}): Promise<boolean> {
  await _fetchBots(params.botId);
  return false;
}

const routes = [
  {
    path: '/',
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: '/home',
      },
      {
        name: 'home',
        path: '/home',
        components: {
          default: () => import('../views/Home.vue'),
          navigation: () => import('../views/Catalog.vue'),
        },
        meta: {
          initialize: initializeBots,
        },
      },
      {
        name: 'bot',
        path: '/bot/:botId',
        components: {
          default: () => import('../views/BotPage.vue'),
          navigation: () => import('../views/Catalog.vue'),
        },
        meta: {
          initialize: initializeBots,
        },
      },
      {
        name: 'flow',
        path: '/flow/:flowId',
        components: {
          default: () => import('../views/FlowPage.vue'),
          navigation: () => import('../views/Catalog.vue'),
        },
        meta: {
          initialize: initializeBots,
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/NotFound.vue'),
  },
] as RouteRecordRaw[];

export default routes;

import { IFlowGraphNodeDatum } from '@/types/index';
import { SimulationNodeDatum } from 'd3';

export class FlowGraphNodeDatum implements IFlowGraphNodeDatum, SimulationNodeDatum {
  id!: string;
  label!: string;
  nodeRadius!: number;
  color!: string;
  activated!: boolean;
  selected!: boolean;
  fx: number | null | undefined;
  fy: number | null | undefined;
  index: number | undefined;
  vx: number | undefined;
  vy: number | undefined;
  x!: number;
  y!: number;

  constructor(flow: Partial<FlowGraphNodeDatum>) {
    Object.assign(this, flow);
  }
}

import { FlowId } from '@/types/common';
import { FlowGraphForcedNodeDatum, IFlowGraphLinkDatum, TriggerType } from '@/types/flowGraph';
import { SimulationLinkDatum } from 'd3';

export class FlowGraphLinkDatum implements IFlowGraphLinkDatum, SimulationLinkDatum<FlowGraphForcedNodeDatum> {
  id!: string;
  startNode!: FlowId;
  endNode!: FlowId;
  triggerType: TriggerType;
  customTrigger!: boolean;
  /**
   * Counts links of the same direction and trigger type.
   * Displayed as a multiplier on the arrow.
   */
  triggerCounter!: number;
  /**
   * Number of the link between same nodes but of different types. Needed for position calculation.
   */
  linkCounter!: number;
  /**
   * Y-offset (as before rotation) of this arrow among other arrows between the same nodes.
   */
  position!: number;
  source!: FlowGraphForcedNodeDatum;
  target!: FlowGraphForcedNodeDatum;
  index?: number | undefined;

  constructor(flow: Partial<FlowGraphLinkDatum>) {
    Object.assign(this, flow);
  }
}

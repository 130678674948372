import { D3SvgGroupSelection } from '@/types';
import * as d3 from 'd3';
import { ZoomTransform } from 'd3';

interface TransitionParams {
  x: number;
  y: number;
  scale: number;
}
export function useZoom(selection: D3SvgGroupSelection) {
  // let currentTransform: TransitionParams = { scale: 1, x: 0, y: 0 };
  const zoom = d3.zoom<SVGGElement, unknown>();
  zoom.scaleExtent([0.1, 3]).on('zoom', zoomer);

  function zoomer({ transform }: { transform: ZoomTransform; }) {
    //const translate = `translate(${transform.x}, ${transform.y}) scale(${transform.k})`;

    //currentTransform = transform;
    selection.attr('transform', transform.toString());
  }

  function zoomToFit() {
    const bounds = selection.node()!.getBBox();
    const parent = selection.node()!.parentElement!.parentElement!;
    const fullWidth = parent.clientWidth;
    const fullHeight = parent.clientHeight;
    const width = bounds.width;
    const height = bounds.height;
    if (width === 0 || height === 0) {
      return; // nothing to fit
    }

    const midX = bounds.x + width / 2;
    const midY = bounds.y + height / 2;

    const svgScale = 0.85 / Math.max(width / fullWidth, height / fullHeight);
    const svgTranslate = { x: fullWidth / 2 - svgScale * midX, y: fullHeight / 2 - svgScale * midY };

    selection.attr('transform', `translate(${svgTranslate.x}, ${svgTranslate.y}) scale(${svgScale})`);

    executeZoomTransition({ x: svgTranslate.x, y: svgTranslate.y, scale: svgScale });
  }

  function executeZoomTransition(transitionParams: TransitionParams) {
    const transition = selection.transition();
    transition.call(zoom.transform, () => getZoomTransition(transitionParams));
  }

  function getZoomTransition(transitionParams: TransitionParams) {
    return d3.zoomIdentity
      .translate(transitionParams.x, transitionParams.y)
      .scale(transitionParams.scale);
  }

  function zoomIn() {
    selection.transition().call(zoom.scaleBy, 1.3);
    //zoom.scaleBy(selection,1.3);
    // selection.transition()
    //zoomTransition(0.3);
  }

  function zoomOut() {
    selection.transition().call(zoom.scaleBy, .7);
    // zoomTransition(-0.3);
  }
/*
  function zoomToSelectedFlow() {
    const parent = selection.node()!.parentElement!.parentElement!;
    const fullWidth = parent.clientWidth;
    const fullHeight = parent.clientHeight;

    const flowSelection = d3.select(`#node_${options.selectedNodeId}`);
    const nodeData = flowSelection.data()[0];

    executeZoomTransition({ x: -nodeData.x + fullWidth / 2, y: -nodeData.y + fullHeight / 2, scale: 1 });
  }
 */
/*
  function zoomTransition(zoomStep: number) {
    const parent = selection.node()!.parentElement!.parentElement!;
    const fullWidth = parent.clientWidth;
    const fullHeight = parent.clientHeight;

    let scale = currentTransform.scale * (1 + zoomStep);

    if (scale > 3) {
      scale = 3;
    }
    if (scale < 0.1) {
      scale = 0.1;
    }

    const relativeScale = scale / currentTransform.scale;
    const x = relativeScale * currentTransform.x - (relativeScale - 1) * fullWidth / 2;
    const y = relativeScale * currentTransform.y - (relativeScale - 1) * fullHeight / 2;

    currentTransform = { x, y, scale };

    executeZoomTransition({ x, y, scale });
  }
*/

  return {
    zoom,
    zoomIn,
    zoomOut,
    zoomToFit,
  };
}


import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExpirationOverlay = _resolveComponent("ExpirationOverlay")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AppPage = _resolveComponent("AppPage")!
  const _component_OrToastContainer = _resolveComponent("OrToastContainer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ExpirationOverlay, {
      "lock-username": _ctx.lockScreenUsername,
      username: _ctx.username,
      "auth-url": _ctx.authUrl,
      "identity-provider": _ctx.identityProvider,
      token: _ctx.token,
      "user-id": _ctx.userId,
      "account-id": _ctx.accountId,
      "cookies-expired": _ctx.cookiesExpired,
      "cookies-another-user": _ctx.cookiesAnotherUser,
      "login-overlay-disabled": false,
      onOnUpdateCookieExpiration: _ctx.cookiesRenewHandler
    }, null, 8, ["lock-username", "username", "auth-url", "identity-provider", "token", "user-id", "account-id", "cookies-expired", "cookies-another-user", "onOnUpdateCookieExpiration"]),
    _createVNode(_component_AppPage, {
      token: _ctx.token,
      config: _ctx.config,
      "service-translations": _ctx.serviceTranslations,
      "is-loading": false
    }, {
      navigation: _withCtx(() => [
        _createVNode(_component_router_view, { name: "navigation" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["token", "config", "service-translations"]),
    _createVNode(_component_OrToastContainer, { class: "bottom-2xl" })
  ]))
}
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65a37f99"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "object-header flex flex-row items-center gap-lg w-full p-lg bg-background" }
const _hoisted_2 = { class: "object-avatar" }
const _hoisted_3 = { class: "object-header-content grow flex flex-col gap-xs" }
const _hoisted_4 = {
  key: 0,
  class: "object-header-status"
}
const _hoisted_5 = { class: "object-title font-headline-2 text-headline-2 text-on-background" }
const _hoisted_6 = { class: "object-description font-body-2-regular text-body-2-regular text-on-background" }
const _hoisted_7 = { class: "additional-content" }
const _hoisted_8 = { class: "tag-container flex flex-wrap items-center gap-sm" }
const _hoisted_9 = {
  key: 0,
  class: "actions self-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrTag = _resolveComponent("OrTag")!
  const _component_OrTagSelector = _resolveComponent("OrTagSelector")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "avatar", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.$slots.status)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "status", {}, undefined, true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "description", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.description), 1)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assignedTags, (tag) => {
          return (_openBlock(), _createBlock(_component_OrTag, {
            key: tag.id,
            color: tag.data.color,
            variant: "reset",
            onReset: ($event: any) => (_ctx.unassign(tag.id))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(tag.data.label), 1)
            ]),
            _: 2
          }, 1032, ["color", "onReset"]))
        }), 128)),
        _createVNode(_component_OrTagSelector, {
          selected: _ctx.tagsModel,
          "onUpdate:selected": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tagsModel) = $event)),
          items: _ctx.allTags
        }, null, 8, ["selected", "items"])
      ])
    ]),
    (_ctx.$slots.actions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}